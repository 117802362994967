import { SortDirection } from '@ct-internal/api';

export const toShortDirection = (
  order: 'ascend' | 'descend' | undefined | null,
): SortDirection | undefined => {
  if (order === 'ascend') {
    return 'asc';
  } else if (order === 'descend') {
    return 'desc';
  }

  return undefined;
};

export const toLongDirection = (
  order: SortDirection | undefined,
): 'ascend' | 'descend' | undefined => {
  if (order === 'asc') {
    return 'ascend';
  } else if (order === 'desc') {
    return 'descend';
  }

  return undefined;
};

export const getSortOrder = (
  sorts: [string, 'asc' | 'desc'][],
  propertyName: string,
): 'ascend' | 'descend' | undefined => {
  const sort = sorts?.find(([prop]) => prop === propertyName);
  if (sort) {
    return toLongDirection(sort[1]);
  }
  return undefined;
};
