import { z } from 'zod';
import { Endpoint, Method, makeSorter, stringToBoolean, dateString } from '../../../infrastructure';
import { CoachAiCheckinSchema, CoachAiCheckinStatuses } from '../schemas';

export const CheckinReportFiltersSchema = z
  .object({
    coach_ids: z.string().array(),
    status: z.enum(CoachAiCheckinStatuses).array(),
    is_reviewed: stringToBoolean,
    date: z.object({
      gte: dateString.nullable(),
      lte: dateString.nullable(),
    }),
    project_id: z.string(),
    person_id: z.string(),
  })
  .partial();
export type CheckinReportFilters = z.infer<typeof CheckinReportFiltersSchema>;

export const CheckinReportSorterSchema = makeSorter(z.enum(['name', 'date']));
export type CheckinReportSorter = z.infer<typeof CheckinReportSorterSchema>;

export const CheckinReportQuerySchema = z.object({
  filters: CheckinReportFiltersSchema.partial().optional(),
  sorter: CheckinReportSorterSchema.optional(),
  offset: z.coerce.number().optional(),
  limit: z.coerce.number().optional(),
});
export type CheckinReportQuery = z.infer<typeof CheckinReportQuerySchema>;

export const CheckinReportResponseSchema = z.object({
  items: CoachAiCheckinSchema.array(),
  offset: z.number(),
  limit: z.number(),
  total: z.number(),
});
export type CheckinReportResponse = z.infer<typeof CheckinReportResponseSchema>;

export type CheckinReportEndpoint = Endpoint<
  Method.GET,
  '/api/coach-ai/checkin-report',
  CheckinReportResponse,
  {},
  CheckinReportQuery,
  {}
>;
