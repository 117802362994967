import moment from 'moment';
import { Button } from 'antd';
import { ActionComponent } from '.';
import { useUpdateNotification } from '@src/queries/Notification';
import { useICESetNoShow } from '@src/queries/InterviewCalendarEvent';
import { EyeInvisibleOutlined } from '@ant-design/icons';
import { useSendGtmEvent } from '@shared/gtm';

export const NoShowInterview: ActionComponent = ({ notification }) => {
  const sendGtmEvent = useSendGtmEvent();
  const { mutateAsync: updateNotification } = useUpdateNotification();

  const { mutateAsync: updateICE } = useICESetNoShow({
    onSuccess: () => {
      updateNotification({
        params: { action_request_id: notification.action_request_id },
        request: { dt_action_completed: moment().toISOString() },
      });

      sendGtmEvent('notification_no_show');
    },
  });

  const markAsNoShow = () => {
    updateICE({
      params: {
        interview_calendar_event_id: notification.metadata?.interview_calendar_event_id as number,
      },
    });
  };

  return (
    <Button type="link" size="small" icon={<EyeInvisibleOutlined />} onClick={markAsNoShow}>
      No show
    </Button>
  );
};

export default NoShowInterview;
