import { useState, useCallback } from 'react';
import mapValues from 'lodash/mapValues';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

import {
  FilterSchema,
  InferFilterQuery,
  InferFilterFieldValues,
  toFilterValues,
} from '@ct-internal/api';
import { FiltersSetup } from '../../types';

function mapFiltersToOperations<T extends FilterSchema>(
  filtersOperations: {
    // eslint-disable-next-line no-unused-vars
    [key in keyof InferFilterQuery<T>]: string | string[];
  },
  filters: InferFilterQuery<T>,
): InferFilterFieldValues<T> {
  const mappedFilters = mapValues(filters, (value, key) => {
    const operations = filtersOperations[key as keyof InferFilterQuery<T>];
    if (Array.isArray(operations)) {
      const [firstValue, secondValue] =
        !value || value[0] == null || isEmpty(value[0]) ? [undefined, undefined] : value[0];
      return {
        [operations[0]]: firstValue,
        [operations[1]]: secondValue,
      };
    } else {
      return {
        [operations as string]: value == null || isEmpty(value) ? undefined : value,
      };
    }
  });

  return toFilterValues<T>(mappedFilters) as InferFilterFieldValues<T>;
}

type useTableFiltersReturn<T extends FilterSchema> = [
  InferFilterQuery<T> | undefined,
  (tableFilters: InferFilterQuery<T>) => void,
  () => void,
];

export const useTableFilters = <T extends FilterSchema>(
  filtersOperations: {
    [key in keyof InferFilterQuery<T>]: string | string[];
  },
  filtersSetup: FiltersSetup<T>,
  defaultFilters: InferFilterQuery<T>,
): useTableFiltersReturn<T> => {
  const [tableFilters, setTableFilters] = useState<InferFilterQuery<T>>();

  const updateTableFilters = useCallback(
    (tableFilters: InferFilterQuery<T>) => {
      const relevantFilters = pick(tableFilters, Object.keys(filtersSetup.filters));
      const nonEmptyFilters = omitBy(tableFilters, (value) => value == null || isEmpty(value));
      const nonEmptyOrRelevantFilters = {
        ...nonEmptyFilters,
        ...relevantFilters,
      };
      const filters = mapFiltersToOperations(filtersOperations, nonEmptyOrRelevantFilters);
      setTableFilters(filters);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [filtersSetup],
  );

  const resetTableFilters = useCallback(() => {
    const filters: Record<string, any> = toFilterValues<T>(
      defaultFilters,
    ) as InferFilterFieldValues<T>;
    const fieldsToReset: Record<string, any> = {};

    Object.keys(filtersSetup.form?.getFieldsValue(true) || {}).forEach((fieldName) => {
      fieldsToReset[fieldName] = undefined;
    });
    filtersSetup.form?.setFieldsValue({
      ...fieldsToReset,
      ...filters,
    } as InferFilterFieldValues<T>);
  }, [filtersSetup, defaultFilters]);

  return [tableFilters, updateTableFilters, resetTableFilters];
};
