import { z } from 'zod';

import {
  CriteriaAssessmentRangeTypeSchema,
  CriteriaAssessmentSchema,
  CriteriaAssessmentStatusSchema,
} from '../../base';

import {
  FilterOperations,
  makeSorter,
  ReportsQuerySchema,
  type Endpoint,
  type Method,
} from '../../../../infrastructure';

export const ListCriteriaAssessmentsFiltersSchema = z
  .object({
    application_id: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      z.string().or(z.array(z.string())),
    ),

    status: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      CriteriaAssessmentStatusSchema.or(z.array(CriteriaAssessmentStatusSchema)),
    ),

    job_id: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      z.string().or(z.array(z.string())),
    ),

    dt_created: z.record(
      z.enum([FilterOperations.GREATER_THAN_OR_EQUAL, FilterOperations.LESS_THAN_OR_EQUAL]),
      z.string(),
    ),

    metAllScoreRanges: z.enum(['true', 'false']),
  })
  .partial();

export type ListCriteriaAssessmentsFilters = z.infer<typeof ListCriteriaAssessmentsFiltersSchema>;

export const ListCriteriaAssessmentsSorterSchema = makeSorter(
  z.enum(['ranking_score', 'dt_created']),
);

export type ListCriteriaAssessmentsSorter = z.infer<typeof ListCriteriaAssessmentsSorterSchema>;

export const ListCriteriaAssessmentsQuerySchema = ReportsQuerySchema.extend({
  filters: ListCriteriaAssessmentsFiltersSchema.optional(),
  sorter: ListCriteriaAssessmentsSorterSchema.optional(),
});

export const ListCriteriaAssessmentsResponseSchema = z.array(
  CriteriaAssessmentSchema.pick({
    assessment_id: true,
    application_id: true,
    status: true,
    ranking_score: true,
    dt_created: true,
    dt_updated: true,
    report_url: true,
    candidate_report_url: true,
  }).extend({
    name: z.string(),
    job_name: z.string(),
    job_short_name: z.string(),
    metAllScoreRanges: z.boolean(),
    task_results: z.record(z.boolean()),
    task_explanations: z.record(
      z.record(
        z.object({
          passed: z.boolean(),
          range: z.string(),
          score: z.number().nullish(),
          score_type: z.string(),
          range_type: CriteriaAssessmentRangeTypeSchema,
        }),
      ),
    ),
  }),
);

export type ListCriteriaAssessmentsResponse = z.infer<typeof ListCriteriaAssessmentsResponseSchema>;

export type ListCriteriaAssessmentsParams = undefined;

export type ListCriteriaAssessmentsQuery = z.infer<typeof ListCriteriaAssessmentsQuerySchema>;

export type ListCriteriaAssessmentsRequest = undefined;

export type ListCriteriaAssessmentsEndpoint = Endpoint<
  Method.GET,
  '/api/v2/criteria-assessment/list',
  ListCriteriaAssessmentsResponse,
  ListCriteriaAssessmentsParams,
  ListCriteriaAssessmentsQuery,
  ListCriteriaAssessmentsRequest
>;
