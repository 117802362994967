import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TargetGroup } from '../schemas';

export const PatchTargetGroupRequestSchema = z.object({
  name: z.string().optional(),
  description: z.string().optional(),
  emails: z.array(z.string()).optional(),
});
export type PatchTargetGroupRequest = z.infer<typeof PatchTargetGroupRequestSchema>;
export type PatchTargetGroupResponse = TargetGroup;

export type PatchTargetGroupEndpoint = Endpoint<
  Method.PATCH,
  '/api/coach-ai/target-groups/:group_id',
  PatchTargetGroupResponse,
  { group_id: number },
  {},
  PatchTargetGroupRequest
>;
