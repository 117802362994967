import { PropsWithChildren } from 'react';
import { Row, Col, Typography } from 'antd';

const { Title } = Typography;

interface HeaderProps extends PropsWithChildren {
  title?: string;
}

const Header = ({ title, children }: HeaderProps) => {
  return (
    <Row justify="space-between" align="middle" style={{ marginBottom: 32 }}>
      <Col>
        <Title level={1} style={{ margin: 0 }}>
          {title}
        </Title>
      </Col>
      {children && <Col>{children}</Col>}
    </Row>
  );
};

export default Header;
