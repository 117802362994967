import { DebounceProps, FilterAdapterProps, InputComponent } from '../types';
import { useCallback, useEffect, useState } from 'react';

import _debounce from 'lodash/debounce';

interface DebouncedAdapterProps extends FilterAdapterProps<any>, DebounceProps {}

const withDebounceAdapter =
  (Component: InputComponent, isNativeEvent = true) =>
  ({ onChange, value, debounce }: DebouncedAdapterProps) => {
    const [inputValue, setInputValue] = useState(value || undefined);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedChange = useCallback(
      _debounce((newValue) => {
        onChange && onChange(newValue);
      }, debounce),
      [onChange],
    );

    useEffect(() => {
      if (value !== inputValue) {
        debouncedChange(inputValue);
      }
      // eslint-disable-next-line
    }, [inputValue]);

    return (
      <Component
        onChange={(e: any) => setInputValue(isNativeEvent ? e.target.value : e)}
        value={inputValue}
      />
    );
  };

export default withDebounceAdapter;
