import { z } from 'zod';
import { ProjectSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

const ProjectSlimSchema = ProjectSchema.pick({
  project_id: true,
  name: true,
});
export type ProjectSlim = z.infer<typeof ProjectSlimSchema>;

export type ListProjectSlimResponse = { projects: ProjectSlim[] };

//Endpoint
export type ListProjectSlimEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-projects/slim',
  ListProjectSlimResponse,
  undefined,
  undefined,
  undefined
>;
