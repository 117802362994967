import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../../infrastructure';
import { PosWarningSchema } from '../../positions';
import { CommentListItemSchema } from '../../comment';

export const PlanRevenueDetailItemSchema = z.object({
  yyear: z.number(),
  ymonth: z.number(),

  client_id: z.string(),
  client_name: z.string(),

  project_id: z.string(),
  project_name: z.string(),
  islead: z.boolean(),
  is_pre_opp: z.number(),
  isproject_a_lead: z.number(),
  activepositions: z.number(),
  is_plan_included: z.boolean(),
  is_starter_project: z.boolean(),
  rev_type: z.string(),
  confidence_level: z.string().nullable(),

  month_revenue: z.number(),
  warnings: z.array(PosWarningSchema).nullable(),
  financial_comments: CommentListItemSchema.array().nullable(),
});

export type PlanRevenueDetailItem = z.infer<typeof PlanRevenueDetailItemSchema>;

export const PlanRevenueDetailFiltersSchema = z.object({
  ymonth_period_start: z
    .record(z.enum([FilterOperations.GREATER_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth_period_end: z
    .record(z.enum([FilterOperations.LESS_THAN_OR_EQUAL]), z.coerce.number())
    .optional(),
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  isInvoicedRevVisible: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  isOppRevVisible: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  isPreOppRevVisible: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
});

export type PlanRevenueDetailFilters = z.infer<typeof PlanRevenueDetailFiltersSchema>;

export const PlanRevenueDetailQuerySchema = z.object({
  filters: PlanRevenueDetailFiltersSchema.optional(),
});
export type PlanRevenueDetailQuery = z.infer<typeof PlanRevenueDetailQuerySchema>;

export type PlanRevenueDetailResponse = PlanRevenueDetailItem[];

export type PlanRevenueDetailEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/monthly-plan-v2/revenue-detail',
  PlanRevenueDetailResponse,
  undefined,
  PlanRevenueDetailQuery,
  undefined
>;
