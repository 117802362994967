import { z } from 'zod';
import type { Endpoint, Method } from '../../../../infrastructure';
import { CriteriaAssessment, CriteriaAssessmentCreateSchema } from '../../base';

export const CreateCriteriaAssessmentsRequestSchema = CriteriaAssessmentCreateSchema.pick({
  application_id: true,
});

export type CreateCriteriaAssessmentsResponse = CriteriaAssessment;

export type CreateCriteriaAssessmentsParams = {};

export type CreateCriteriaAssessmentsQuery = undefined;

export type CreateCriteriaAssessmentsRequest = z.infer<
  typeof CreateCriteriaAssessmentsRequestSchema
>;

export type CreateCriteriaAssessmentsEndpoint = Endpoint<
  Method.POST,
  '/api/v2/criteria-assessment/schedule',
  CreateCriteriaAssessmentsResponse,
  CreateCriteriaAssessmentsParams,
  CreateCriteriaAssessmentsQuery,
  CreateCriteriaAssessmentsRequest
>;
