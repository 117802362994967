import { Form, Col, Input } from 'antd';

import { DebounceProps, FilterItem, InputComponent } from '../../types';
import withDebounceAdapter from '../../hoc/withDebounceAdapter';

interface FilterTextProps extends FilterItem, DebounceProps {}

const DebounceAdapter = withDebounceAdapter(Input as InputComponent);

const FilterText = ({ name, label, debounce, ...props }: FilterTextProps) => {
  return (
    <Col {...props}>
      <Form.Item name={name} label={label}>
        {debounce ? <DebounceAdapter debounce={debounce} /> : <Input />}
      </Form.Item>
    </Col>
  );
};

export default FilterText;
