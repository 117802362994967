import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router';

import map from 'lodash/map';
import get from 'lodash/get';
import reduce from 'lodash/reduce';

import { UseQueryOptions, UseQueryResult, useQuery } from '@tanstack/react-query';
import { AnnouncementEndpoints, ListAnnouncementsResponse } from '@ct-internal/api';

import { ANNOUNCEMENTS_KEY } from './keys';
import APIRequest from '../../../utils/request';

export type NavigationData = {
  href: string;
  subitems?: NavigationData[];
};

export function useListAnnouncements({
  options,
  query,
  navigationData = [],
}: {
  options?: UseQueryOptions<AnnouncementEndpoints['list']['response']>;
  query?: { filters: Omit<AnnouncementEndpoints['list']['query']['filters'], 'app_path'> };
  navigationData?: NavigationData[];
}): UseQueryResult<ListAnnouncementsResponse> {
  const { pathname } = useLocation();

  const routerData = useMemo(
    () =>
      reduce(
        navigationData,
        (acc: Array<NavigationData>, { subitems = [], ...item }) => {
          return [...acc, item, ...map(subitems, (subitem) => ({ ...subitem }))];
        },
        [],
      ),
    [navigationData],
  );

  const app_path = useMemo(() => {
    const route = routerData.find((item) => {
      return matchPath(pathname, {
        path: item.href,
        exact: true,
        strict: false,
      });
    });

    return !get(route, 'href') || get(route, 'href') === pathname ? pathname : route?.href;
  }, [pathname, routerData]);

  return useQuery({
    queryKey: [ANNOUNCEMENTS_KEY, query, app_path],
    queryFn: () =>
      APIRequest('/announcement', {
        method: 'GET',
        qs: { ...query, filters: { ...query?.filters, app_path } },
        qsOptions: { arrayFormat: null },
        headers: {
          Accept: 'application/json',
        },
      }),
    ...options,
  });
}
