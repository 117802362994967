import { ReactNode } from 'react';
import { TableColumnType, TableColumnGroupType } from 'antd';

import ReportTableContext from '../../context/Context';
import { SortDirection } from '@ct-internal/api';
import { toLongDirection } from '../../utils/sorter';

export type InferDataIndex<T extends {}> =
  | keyof T
  | ({ [key in keyof T]: T[key] extends {} ? [key, keyof T[key]] : 'NOT_A_KEY' } extends {
      [key: string]: infer U;
    }
      ? U
      : never);

type InferRecordValue<T extends {}, K extends InferDataIndex<T>> = K extends keyof T
  ? T[K]
  : K extends [infer PK, infer CK]
  ? PK extends keyof T
    ? CK extends keyof T[PK]
      ? T[PK][CK]
      : never
    : never
  : never;

export type ColumnProps<
  T extends {},
  K extends InferDataIndex<T>,
  C extends {} | undefined = undefined,
> = {
  width?: string | number;
  title: TableColumnType<T>['title'];
  align?: TableColumnType<T>['align'];
  sorter?: TableColumnType<T>['sorter'];
  sortOrder?: 'ascend' | 'descend' | undefined;
  defaultSortOrder?: SortDirection;
  className?: TableColumnType<T>['className'];
  children?: TableColumnGroupType<T>['children'];
  fixed?: TableColumnType<T>['fixed'];
  responsive?: TableColumnType<T>['responsive'];
  onCell?: TableColumnType<T>['onCell'];
  getColSpan?: (record: T) => number | undefined;
  priority?: number;

  filters?: TableColumnType<T>['filters'];
  onFilter?: TableColumnType<T>['onFilter'];
  filterDropdown?: TableColumnType<T>['filterDropdown'];
  filterSearch?: TableColumnType<T>['filterSearch'];
  filterMode?: TableColumnType<T>['filterMode'];
  filterMultiple?: TableColumnType<T>['filterMultiple'];
  filteredValue?: TableColumnType<T>['filteredValue'];

  render?: (
    value: InferRecordValue<T, K>,
    record: T,
    index: number,
    context?: C,
  ) => ReactNode | string;
} & ({ dataIndex: K; key?: string } | { dataIndex?: K; key: string });

const createRender = <
  T extends {},
  K extends InferDataIndex<T>,
  C extends {} | undefined = undefined,
>(
  props: ColumnProps<T, K, C>,
) => {
  let render;
  if (props.render?.length === 4) {
    render = (value: T[keyof T], record: T, index: number) => (
      <ReportTableContext.Consumer>
        {(context) => props.render && props.render(value as any, record, index, context as C)}
      </ReportTableContext.Consumer>
    );
  } else {
    render = props.render;
  }

  return { render };
};

const createDefaultSortOrder = <
  T extends {},
  K extends InferDataIndex<T>,
  C extends {} | undefined = undefined,
>(
  props: ColumnProps<T, K, C>,
) => ({
  defaultSortOrder: toLongDirection(props.defaultSortOrder),
});

const Column =
  <T extends {}, C extends {} | undefined = undefined>() =>
  <K extends InferDataIndex<T>>(props: ColumnProps<T, K, C>): TableColumnType<T> => ({
    ...props,
    // Out index is more specific and it helps resolve proper value but ant is requiring this one
    dataIndex: props.dataIndex as TableColumnType<T>['dataIndex'],
    className: props.className as TableColumnType<T>['className'],
    ...createDefaultSortOrder(props),
    ...createRender(props),
  });

export default Column;
