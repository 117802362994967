import { PropsWithChildren } from 'react';
import { Button } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { BlobRequest, CSVRequest } from '@ct-internal/api';

import useDownload from '../../hooks/useDownload';

import './index.css';

export interface DownloadProps<T extends CSVRequest | BlobRequest> extends PropsWithChildren {
  load: T;
  filename: string;
  type?: 'text';
  className?: string;
  width?: string;
  justIcon?: boolean;
  disabled?: boolean;
}

const Download = <T extends CSVRequest | BlobRequest>({
  load,
  filename,
  children,
  type,
  className,
  width,
  justIcon,
  disabled = false,
}: DownloadProps<T>) => {
  const { download, isLoading } = useDownload(load, filename);

  return (
    <Button
      onClick={download}
      disabled={disabled || isLoading}
      loading={isLoading}
      type={type}
      className={`report-button ${className}`}
      style={{ width: width ? width : 'auto' }}
    >
      <DownloadOutlined />
      {justIcon ? null : children}
    </Button>
  );
};

Download.defaultProps = {
  children: 'Download',
};

export default Download;
