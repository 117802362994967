import SummaryContainer from './components/Container';
import SummaryHeader from './components/Header';
import SummaryRow from './components/Row';

const ReportSummary = SummaryContainer as typeof SummaryContainer & {
  Header: typeof SummaryHeader;
  Row: typeof SummaryRow;
};
ReportSummary.Header = SummaryHeader;
ReportSummary.Row = SummaryRow;

export { ReportSummary };
