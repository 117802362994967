import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { PersonInfo } from '../schemas';

export const GetPersonInfoQuerySchema = z.object({
  email: z.string(),
});
export type GetPersonInfoQuery = z.infer<typeof GetPersonInfoQuerySchema>;

export type GetPersonInfoResponse = PersonInfo;

export type GetPersonInfoEndpoint = Endpoint<
  Method.GET,
  '/api/coach-ai/person-info',
  GetPersonInfoResponse,
  {},
  GetPersonInfoQuery,
  {}
>;
