import moment from 'moment';
import { Link } from 'react-router-dom';
import { Flex, Typography, Descriptions } from 'antd';
import { NotificationConfigItem, NotificationConfigMessage } from '.';

export const ApplicationRejectedWithPendingInterviewsMessage: NotificationConfigMessage = (
  notification,
) => {
  return `Interview with ${
    notification.metadata?.applicant_name ?? ''
  } is pending decision, but application is closed. Please close taking one of the following decisions.`;
};

export const ApplicationRejectedWithPendingInterviews: NotificationConfigItem = {
  title: 'Interview Open but application closed',
  getDescription: (notification) =>
    notification.metadata && (
      <Flex gap={8} vertical>
        <Typography.Text>
          Interview is pending decision but application{' '}
          <Link
            target="_blank"
            to={`/admin/application/${notification.metadata.application_id}`}
            style={{ textDecoration: 'underline', color: 'inherit' }}
          >
            {notification.metadata.applicant_name as string}
          </Link>{' '}
          is already closed. Please close the interview.
        </Typography.Text>
        <Descriptions bordered size="small">
          <Descriptions.Item label="Interview">
            {moment(notification.metadata.interview_date as string).format('ll HH:mm')}
          </Descriptions.Item>
        </Descriptions>
      </Flex>
    ),
};
