import { useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';

export const useSendGtmEvent = () => {
  const user = useSelector((state: any) => state?.authentication?.user) ?? {};

  return (type: string, data: any = {}) => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'custom_user_action',
        data: {
          type,
          user: {
            user_id: user?._id,
            user_name: user?.name,
            user_email: user?.email,
          },
          ...data,
        },
      },
    });
  };
};
