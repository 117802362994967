import { PropsWithChildren } from 'react';
import { Button } from 'antd';
import { DiffOutlined } from '@ant-design/icons';

import { CSVCompareRequest } from '@ct-internal/api';

import { useCSVCompare } from '../../hooks/useCSVCompare';

export interface CSVCompareProps<T extends CSVCompareRequest> extends PropsWithChildren {
  loadCSVComparison: T;
  type?: 'text';
  onOk?: () => void;
}

const CSVCompare = <T extends CSVCompareRequest>({
  loadCSVComparison,
  children,
  type,
  onOk,
}: CSVCompareProps<T>) => {
  const { compareCSV, isLoading } = useCSVCompare(loadCSVComparison, onOk);

  return (
    <Button onClick={compareCSV} disabled={isLoading} loading={isLoading} type={type}>
      <DiffOutlined />
      {children}
    </Button>
  );
};

CSVCompare.defaultProps = {
  children: 'Compare CSV',
};

export default CSVCompare;
