import { Form, Col, Select, SelectProps } from 'antd';
import { FilterItem } from '../../types';

interface FilterSelectProps<T extends {}> extends FilterItem, SelectProps<any, T> {}

const FilterSelect = <T extends {}>({
  name,
  label,
  span,
  xs,
  sm,
  md,
  lg,
  xl,
  xxl,
  ...selectProps
}: FilterSelectProps<T>) => {
  return (
    <Col span={span} xs={xs} sm={sm} md={md} lg={lg} xl={xl} xxl={xxl}>
      <Form.Item name={name} label={label}>
        <Select style={{ width: '100%' }} {...selectProps} />
      </Form.Item>
    </Col>
  );
};

FilterSelect.renderOptions = (options: (string | number)[]) => {
  return options.map((option) => (
    <Select.Option key={option} value={option}>
      {option}
    </Select.Option>
  ));
};

export default FilterSelect;
