import React from 'react';
import { Form, Row, Col, Button, Alert } from 'antd';
import { FilterSchema } from '@ct-internal/api';
import { FiltersContainerProps } from '../index';

const FilterForm = <T extends FilterSchema>({
  onFilter,
  children,
  // Omitting fields; Antd form also uses fields prop that isn't matching
  fields: _fields,
  errors,
  ready,
  onReset,
  showResetButton = true,
  ...filtersSetup
}: FiltersContainerProps<T>) => {
  return (
    <Form
      {...filtersSetup}
      name="filters"
      onFinish={() => onFilter && ready && onFilter(filtersSetup.filters)}
      layout="vertical"
    >
      {children}
      <Row gutter={12}>
        {filtersSetup.form && showResetButton && (
          <Col>
            <Button
              onClick={() => {
                filtersSetup.form.resetFields();
                onReset?.();
              }}
            >
              Reset
            </Button>
          </Col>
        )}
        {onFilter && (
          <Col>
            <Button htmlType="submit">Apply</Button>
          </Col>
        )}
      </Row>
      {errors?.message && (
        <Row gutter={12} style={{ marginTop: showResetButton ? 24 : 0 }}>
          <Col>
            <Alert message={errors.message} type="error" closable />
          </Col>
        </Row>
      )}
    </Form>
  );
};

export default FilterForm;
