import { Tooltip, Tag, Row, Col, InputNumber, Form } from 'antd';
import React, { useState } from 'react';

import clone from 'lodash/clone';
import { ClearOutlined, SearchOutlined } from '@ant-design/icons';

interface FilterNumberRangeProps {
  maxName: string;
  minName: string;
  label: string;
}
function FilterNumberRange(props: FilterNumberRangeProps) {
  const { maxName, minName, label } = props;

  const [range, setRange] = useState<[number | undefined, number | undefined]>([
    undefined,
    undefined,
  ]);

  const form = Form.useFormInstance();
  const minValue = Form.useWatch(minName, form);

  const handleMinChange = (minValue: number | undefined) => {
    const currentRange = clone(range) ?? [];
    setRange([minValue, currentRange[1]]);
  };
  const handleMaxChange = (maxValue: number | undefined) => {
    const currentRange = clone(range) ?? [];
    setRange([currentRange[0], maxValue]);
  };

  return (
    <>
      <Row style={{ padding: '0 0 8px', fontWeight: 'bold' }} gutter={4}>
        <Col>{label}</Col>
        <Col>
          <Tooltip title="Click to search or press enter">
            <Tag
              icon={<SearchOutlined />}
              style={{ cursor: 'pointer' }}
              onClick={() =>
                form.setFieldsValue({
                  [minName]: range[0],
                  [maxName]: range[1],
                })
              }
            />
          </Tooltip>
          <Tooltip title="Click to clear filter">
            <Tag
              icon={<ClearOutlined />}
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setRange([undefined, undefined]);
                form.setFieldsValue({
                  [minName]: undefined,
                  [maxName]: undefined,
                });
              }}
            />
          </Tooltip>
        </Col>
      </Row>
      <Row gutter={16}>
        <Col>
          <InputNumber
            placeholder="Min"
            value={range[0]}
            min={0}
            onChange={(value) => handleMinChange(value ?? undefined)}
            onKeyDown={(e) =>
              e.key === 'Enter'
                ? form.setFieldsValue({
                    [minName]: range[0],
                    [maxName]: range[1],
                  })
                : null
            }
          />
        </Col>
        <Col>To</Col>
        <Col>
          <InputNumber
            placeholder="Max"
            value={range[1]}
            min={minValue ?? 0}
            onChange={(value) => handleMaxChange(value ?? undefined)}
            onKeyDown={(e) =>
              e.key === 'Enter'
                ? form.setFieldsValue({
                    [minName]: range[0],
                    [maxName]: range[1],
                  })
                : null
            }
          />
        </Col>
      </Row>
    </>
  );
}
export default FilterNumberRange;
