import { Form, Col, InputNumber } from 'antd';

import { DebounceProps, FilterItem } from '../../types';
import withDebounceAdapter from '../../hoc/withDebounceAdapter';

interface FilterNumberProps extends FilterItem, DebounceProps {}

const DebounceAdapter = withDebounceAdapter(InputNumber, false);

const FilterNumber = ({ name, label, debounce, ...props }: FilterNumberProps) => {
  return (
    <Col {...props}>
      <Form.Item name={name} label={label}>
        {debounce ? <DebounceAdapter debounce={debounce} /> : <InputNumber />}
      </Form.Item>
    </Col>
  );
};

export default FilterNumber;
