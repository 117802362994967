import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TCoachAiCheckinSchema } from '../schemas';

export const PatchCheckinRequestSchema = z.object({
  status: TCoachAiCheckinSchema.shape.status.optional(),
  reviewed: z.boolean().nullable().optional(),
});
export type PatchCheckinRequest = z.infer<typeof PatchCheckinRequestSchema>;

export type PatchCheckinEndpoint = Endpoint<
  Method.PATCH,
  '/api/v2/coach-ai/checkin/:checkin_id',
  {},
  { checkin_id: number },
  {},
  PatchCheckinRequest
>;
