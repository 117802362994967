import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TargetGroup } from '../schemas';

export const ListTargetGroupsQuerySchema = z.object({
  limit: z.coerce.number().default(50),
  offset: z.coerce.number().default(0),
});
export type ListTargetGroupsQuery = z.infer<typeof ListTargetGroupsQuerySchema>;

export type ListTargetGroupsResponse = {
  items: TargetGroup[];
  limit: number;
  offset: number;
  total: number;
};

export type ListTargetGroupsEndpoint = Endpoint<
  Method.GET,
  '/api/coach-ai/target-groups',
  ListTargetGroupsResponse,
  {},
  ListTargetGroupsQuery,
  {}
>;
