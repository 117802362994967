import { Link } from 'react-router-dom';
import { Flex, Typography } from 'antd';
import { NotificationConfigItem, NotificationConfigMessage } from '.';

export const NoShowInterviewsMessage: NotificationConfigMessage = (notification) => {
  return `Interview with ${
    notification.metadata?.applicant_name ?? ''
  } is as No show. Please drop it or reschedule.`;
};

export const NoShowInterviews: NotificationConfigItem = {
  title: 'Interview as No show',
  getDescription: (notification) =>
    notification.metadata && (
      <Flex gap={8} vertical>
        <Typography.Text>
          The application of{' '}
          <Link
            target="_blank"
            to={`/admin/application/${notification.metadata.application_id}`}
            style={{ textDecoration: 'underline', color: 'inherit' }}
          >
            {notification.metadata.applicant_name as string}
          </Link>{' '}
          have a interview as No show. Please drop it or reschedule it.
        </Typography.Text>
      </Flex>
    ),
};
