import { z, SafeParseError, ZodIssue, ZodInvalidUnionIssue } from 'zod';
import { createFilterFieldName, FilterOperations, FilterSchema } from '@ct-internal/api';
import { FormErrors } from '../types';

const formatSchemaErrors = (errors: ZodIssue[]) => {
  return errors.reduce((errors, issue) => {
    const [field, operation] = issue.path;
    if (!operation) {
      // This is the case when value doesn't exist at all in union type
      return errors;
    }
    const fieldName = createFilterFieldName(field as string, operation as FilterOperations);
    errors[fieldName] = issue.message;
    return errors;
  }, {} as Record<string, string>);
};

const formatUnionSchemaErrors = (validation: SafeParseError<any>): FormErrors => {
  const formErrors: FormErrors = {};
  const errors = (validation.error.errors?.[0] as ZodInvalidUnionIssue)?.unionErrors?.reduce(
    (errors, unionErrors) => {
      return {
        ...errors,
        ...formatSchemaErrors(unionErrors.errors),
      };
    },
    {},
  );

  if (!errors || Object.keys(errors).length === 0) {
    formErrors.message = 'Invalid filters combination';
  } else {
    formErrors.errors = errors;
  }

  return formErrors;
};

const NO_ERRORS = {};
export const validateSchemaValues = <T extends FilterSchema>(
  schema: T,
  values: z.infer<T>,
): FormErrors => {
  const validation = schema.safeParse(values);

  if (validation.success) {
    return NO_ERRORS;
  }

  if (schema._def.typeName === 'ZodUnion') {
    return formatUnionSchemaErrors(validation as SafeParseError<any>);
  } else if (schema._def.typeName === 'ZodObject') {
    return { errors: formatSchemaErrors((validation as SafeParseError<any>).error.errors) };
  }

  console.error(schema);
  throw new Error('Unknown schema type');
};
