import { z } from 'zod';

import { Endpoint, Method } from '../../../infrastructure';

export const CreateBusinessEventRequestBodySchema = z.object({
  businessActionName: z.string(),
  relatedEntityId: z.string(),
  issuedByIntegration: z.string().nullable().optional(),
  changes: z
    .array(
      z.object({
        name: z.string(),
        old: z.any(),
        new: z.any(),
      }),
    )
    .nullable(),
});
export type CreateBusinessEventRequestBody = z.infer<typeof CreateBusinessEventRequestBodySchema>;

export type CreateBusinessEventEndpoint = Endpoint<
  Method.POST,
  '/api/v2/business-events',
  undefined,
  undefined,
  undefined,
  CreateBusinessEventRequestBody
>;
