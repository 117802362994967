import { z } from 'zod';
import { PersonSchema } from './base';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

export const ListPeopleSlimSchema = PersonSchema.pick({
  name: true,
  person_id: true,
  email: true,
  dtterminated: true,
  dtstart: true,
  gravatar_url: true,
}).extend({
  isbillable: z.boolean(),
});

export type ListPeopleSlim = z.infer<typeof ListPeopleSlimSchema>;

export type ListPeopleSlimResponse = ListPeopleSlim[];

export const ListPeopleSlimFiltersSchema = z.object({
  name: z.record(z.enum([FilterOperations.CONTAIN]), z.array(z.string())).optional(),
  personId: z.record(z.enum([FilterOperations.IN]), z.array(z.string())).optional(),
  isbillable: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.boolean()).optional(),
  isactive: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.boolean()).optional(),
});
export type ListPeopleSlimFilters = z.infer<typeof ListPeopleSlimFiltersSchema>;

export const ListPeopleSlimQuerySchema = z.object({
  filters: ListPeopleSlimFiltersSchema.optional(),
});
export type ListPeopleSlimQuery = z.infer<typeof ListPeopleSlimQuerySchema>;

export type ListPeopleSlimEndpoint = Endpoint<
  Method.GET,
  `/api/v2/people/list-slim`,
  ListPeopleSlimResponse,
  undefined,
  ListPeopleSlimQuery,
  undefined
>;
