import { ReactNode } from 'react';
import { Row, Col } from 'antd';

import './index.css';

interface SummaryRowProps {
  label: string;
  children: ReactNode;
  className?: string;
}

const SummaryRow = ({ children, label, className }: SummaryRowProps) => (
  <Row className={className}>
    <Col flex="auto">
      <b>{label}</b>
    </Col>
    <Col flex="none">{children}</Col>
  </Row>
);

export default SummaryRow;
