import { z } from 'zod';
import { Endpoint, FilterOperations, Method } from '../../infrastructure';

export const PrjCltRevCostMonthlyReportItemSchema = z.object({
  ymonth: z.number(),
  project_serial_id: z.string(),
  project_name: z.string(),
  client_serial_id: z.string(),
  client_name: z.string(),
  client_short_name: z.string(),
  industry: z.string().nullable(),
  size: z.string().nullable(),
  rev_description: z.string(),
  month_revenue: z.number(),
  month_revenue_for_summary: z.number(),
  cost: z.number(),
  cost_without_product_analyst: z.number(),
  cost_product_analyst: z.number(),
  owner_name: z.string(),
  country_id: z.string().nullable(),
  state_or_province: z.string().nullable(),
});
export type PrjCltRevCostMonthlyReportItem = z.infer<typeof PrjCltRevCostMonthlyReportItemSchema>;

export const PrjCltRevCostMonthlyReportFiltersSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()),
});
export type PrjCltRevCostMonthlyReportFilters = z.infer<
  typeof PrjCltRevCostMonthlyReportFiltersSchema
>;

export const PrjCltRevCostMonthlyReportQuerySchema = z.object({
  filters: PrjCltRevCostMonthlyReportFiltersSchema.optional(),
});
export type PrjCltRevCostMonthlyReportQuery = z.infer<typeof PrjCltRevCostMonthlyReportQuerySchema>;

export type PrjCltRevCostMonthlyReportResponse = PrjCltRevCostMonthlyReportItem[];

export type PrjCltRevCostMonthlyReportEndpoint = Endpoint<
  Method.GET,
  '/api/v2/sql-reports/prj-clt-rev-cost-monthly',
  PrjCltRevCostMonthlyReportResponse,
  undefined,
  PrjCltRevCostMonthlyReportQuery,
  undefined
>;
