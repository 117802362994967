import { useCallback, useState } from 'react';
import { Modal, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

import { CSVCompareRequest } from '@ct-internal/api';

export const useCSVCompare = (loadCSVComparison: CSVCompareRequest, onOk?: () => void) => {
  const [isLoading, setIsLoading] = useState(false);

  const compareCSV = useCallback(async () => {
    setIsLoading(true);
    try {
      const { html } = await loadCSVComparison();
      Modal.confirm({
        title: 'Do the changes look good?',
        icon: <ExclamationCircleOutlined />,
        content: (
          <div className="compare-payments-table" dangerouslySetInnerHTML={{ __html: html }} />
        ),
        width: '100%',
        okText: 'Confirm and use new data for current month',
        onOk,
      });
    } catch (e) {
      message.error(e instanceof Error ? e.message : typeof e === 'string' ? e : 'Error');
    }

    setIsLoading(false);
  }, [loadCSVComparison, onOk]);

  return { compareCSV, isLoading };
};
