import './index.less';

import React from 'react';
import { useMemo, useState } from 'react';
import { List, Modal, Popover, Typography, Spin } from 'antd';

import { FilterOperations } from '@ct-internal/api';
import { NavigationData, useListAnnouncements } from './query/queries';

import Magaphone from './megaphone.png';

const marked = require('marked');

function AnnouncementItem({ preview, message }: { preview: string; message: string }) {
  const [openModal, setOpenModal] = useState(false);
  const parsedPreview = marked(preview ?? '#### _Click "Read more" to see the announcement!_');
  const parsedMessage = marked(message ?? '');

  return (
    <>
      <List.Item className="app-announcement-list-item">
        <div
          style={{ maxWidth: '100%', width: '100%' }}
          dangerouslySetInnerHTML={{ __html: parsedPreview }}
          className="app-announcement-prose app-announcement-prose-lg app-announcement-clamp-3"
        />
        {message && (
          <button onClick={() => setOpenModal(true)} className="app-announcements-read-more">
            Read more
          </button>
        )}
      </List.Item>
      <Modal
        open={openModal}
        closable={false}
        onOk={() => setOpenModal(false)}
        cancelButtonProps={{ style: { display: 'none' } }}
      >
        <div
          style={{ maxWidth: '100%', width: '100%' }}
          dangerouslySetInnerHTML={{ __html: parsedMessage }}
          className="app-announcement-prose app-announcement-prose-lg"
        />
      </Modal>
    </>
  );
}

export default function Announcements({
  app,
  navigationData,
}: {
  app: string;
  navigationData: NavigationData[];
}) {
  const { data, error, isLoading } = useListAnnouncements({
    navigationData,
    query: {
      filters: {
        app: {
          [FilterOperations.EQUAL]: app,
        },
      },
    },
  });

  const newItems = useMemo(() => {
    return (data || []).length > 0;
  }, [data]);

  const content = useMemo(() => {
    if (error || !data) {
      return <div>Error Getting Announcements</div>;
    }

    return (
      <Spin spinning={isLoading}>
        <List
          dataSource={data}
          locale={{ emptyText: 'No announcements' }}
          header={<Typography.Title level={5}>What's new</Typography.Title>}
          renderItem={(item) => <AnnouncementItem preview={item.preview} message={item.message} />}
          className="announcement-list"
        />
      </Spin>
    );
  }, [data, error, isLoading]);

  if (!newItems) {
    return null;
  }

  return (
    <div className="app-announcements">
      <Popover
        arrow={false}
        trigger="click"
        content={content}
        placement="bottomRight"
        overlayClassName="app-announcements-popover"
      >
        <div className="app-announcements-button-container">
          <button aria-label="Toggle announcements" className="app-announcements-button">
            <img src={Magaphone} alt="" />
          </button>
        </div>
      </Popover>
    </div>
  );
}
