import { Form, Col, DatePicker } from 'antd';
import { FilterAdapterProps, FilterItem } from '../../types';
import { formatDateValue } from '../../utils/date';
import dayjs from 'dayjs';

const DefaultFormat = 'YYYY';

interface YearProps {
  // Format display value
  format?: string;
  // Format filter value
  formatValue?: string;
  allowClear?: boolean;
  onChange?: (value: string | undefined) => void;
}

const { YearPicker } = DatePicker;

interface FilterMonthProps extends FilterItem, YearProps {}
interface DateAdapterProps
  extends Omit<YearProps, 'onChange'>,
    FilterAdapterProps<string | undefined> {}

const YearAdapter = ({
  value,
  onChange,
  format = DefaultFormat,
  formatValue,
  allowClear,
}: DateAdapterProps) => {
  return (
    <YearPicker
      value={dayjs(formatDateValue(value)?.toDate())}
      format={format}
      allowClear={allowClear}
      onChange={(date) => onChange && onChange(date?.format(formatValue || format))}
      data-cy="filter-year-picker"
    />
  );
};

const FilterYear = ({
  name,
  label,
  span,
  format,
  formatValue,
  allowClear,
  onChange,
}: FilterMonthProps) => {
  return (
    <Col span={span}>
      <Form.Item name={name} label={label}>
        <YearAdapter
          format={format}
          formatValue={formatValue}
          allowClear={allowClear}
          onChange={onChange}
        />
      </Form.Item>
    </Col>
  );
};

export default FilterYear;
