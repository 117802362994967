import { z } from 'zod';

import { FilterOperations, ReportsQuerySchema } from '../../../infrastructure';

export const ListAnnouncementsFiltersSchema = z
  .object({
    app_path: z.string().or(z.array(z.string())),
    announcement_id: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      z.string().or(z.array(z.string())),
    ),
    app: z.record(
      z.enum([FilterOperations.EQUAL, FilterOperations.IN]),
      z.string().or(z.array(z.string())),
    ),
  })
  .partial();

export type ListAnnouncementsFilters = z.infer<typeof ListAnnouncementsFiltersSchema>;

export const ListAnnouncementsQuerySchema = ReportsQuerySchema.pick({ filters: true }).extend({
  filters: ListAnnouncementsFiltersSchema.optional(),
});

export type ListAnnouncementsQuery = z.infer<typeof ListAnnouncementsQuerySchema>;
