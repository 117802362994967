import { z } from 'zod';
import { TimeOffRequestSchema } from '../base';
import { dateString, Endpoint, Method } from '../../../infrastructure';

export const TimeOffRequestGetByIdItemSchema = TimeOffRequestSchema.pick({
  approved_by: true,
  category: true,
  date_range: true,
  declined_by: true,
  dt_approved: true,
  dt_created: true,
  dt_declined: true,
  dt_updated: true,
  exceptional_not_counted: true,
  is_flexible: true,
  is_from_hire: true,
  is_paid: true,
  last_year_days: true,
  mongo_id: true,
  person_id: true,
  status: true,
  time_off_request_id: true,
  working_days: true,
  working_days_last_year: true,
  working_days_next_year: true,
  year: true,
  manual_adjustment: true,
}).extend({
  person_name: z.string(),
  dthired: dateString,
});
export type TimeOffRequestGetByIdItem = z.infer<typeof TimeOffRequestGetByIdItemSchema>;

export const TimeOffRequestGetByIdParamsSchema = z.object({
  time_off_request_id: z.coerce.number(),
});

export type TimeOffRequestGetByIdParams = z.infer<typeof TimeOffRequestGetByIdParamsSchema>;

export type TimeOffRequestGetByIdResponse = TimeOffRequestGetByIdItem | null;

export type TimeOffRequestGetByIdEndpoint = Endpoint<
  Method.GET,
  `/api/v2/time-off-requests/${TimeOffRequestGetByIdParams['time_off_request_id']}`,
  TimeOffRequestGetByIdResponse,
  TimeOffRequestGetByIdParams,
  undefined,
  undefined
>;
