import React, { useState, useMemo } from 'react';
import { Descriptions, Grid, Tooltip } from 'antd';
import type { TableProps } from 'antd';
import type { RenderedCell } from 'rc-table/lib/interface';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import isArray from 'lodash/isArray';
import get from 'lodash/get';
import { ColumnType } from './index';

function instanceOfRenderedCell<T>(object: any): object is RenderedCell<T> {
  return object && typeof object === 'object' && 'children' in object;
}

type ExpandableTableProps = {
  style: React.CSSProperties;
  showAllColumns?: boolean;
} & React.PropsWithChildren;

export const ExpandableTable = (props: ExpandableTableProps) => {
  return (
    <table
      className="table-vertical-view"
      style={{ ...props.style, width: props.showAllColumns ? 'max-content ' : '100%' }}
    >
      <colgroup>
        <col className="table-vertical-view-icon-col" />
      </colgroup>
      {props.children}
    </table>
  );
};

export const ExpandableHeader = (props: React.PropsWithChildren) => {
  return (
    <tr>
      <th className="ant-table-cell"></th>
      {props.children}
    </tr>
  );
};

type ExpandableRowProps<T extends {}> = {
  rowKey?: TableProps<T>['rowKey'];
  columns: ColumnType<T>[];
  dataSource: T[];
  expandable?: TableProps<T>['expandable'];
  'data-row-key': string;
  className?: string;
} & React.PropsWithChildren;

export const ExpandableRow = <T extends {}>(props: ExpandableRowProps<T>) => {
  const { rowKey, columns, dataSource, children, expandable, ...rest } = props;
  const { useBreakpoint } = Grid;

  const screens = useBreakpoint();

  const [openDescription, setOpenDescription] = useState(false);

  const record = dataSource.find((data: any) =>
    typeof rowKey === 'function'
      ? rowKey(data) === rest['data-row-key']
      : data[rowKey] === rest['data-row-key'],
  );

  const labelsWidth = useMemo(
    () =>
      Math.max(
        ...columns.map((c) =>
          typeof c?.title === 'function' ? 0 : c?.title?.toString().length ?? 0,
        ),
      ) +
      2 +
      'rem',
    [columns],
  );

  if (!record || rest.className?.includes('ant-table-expanded-row')) {
    return null;
  }

  const index = dataSource.indexOf(record);

  const handleExpand =
    (value: boolean): React.MouseEventHandler<SVGElement> =>
    (e) => {
      e.stopPropagation();
      setOpenDescription(value);
    };

  return (
    <>
      <tr {...rest}>
        <td className="ant-table-cell ant-table-expand-vertical">
          {openDescription ? (
            <Tooltip title="Close vertical view">
              <BsChevronUp onClick={handleExpand(false)} />
            </Tooltip>
          ) : (
            <Tooltip title="View row vertically">
              <BsChevronDown onClick={handleExpand(true)} />
            </Tooltip>
          )}
        </td>
        {children}
      </tr>
      {expandable?.expandedRowKeys?.includes(rest['data-row-key']) &&
        expandable?.rowExpandable?.(record) && (
          <tr className="ant-table-expanded-row ant-table-expanded-row-level-1">
            <td className="ant-table-cell" colSpan={columns.length + 3}>
              <div
                style={{
                  width: `calc(100vw - ${screens.md ? 304 : 54}px)`,
                  position: 'sticky',
                  left: 6,
                }}
              >
                {expandable?.expandedRowRender?.(record, index, 0, true)}
              </div>
            </td>
          </tr>
        )}
      {record && openDescription && (
        <tr>
          <td colSpan={100}>
            <Descriptions
              bordered
              items={props.columns
                ?.filter((col) => col.priority !== 1)
                .map((col: any) => {
                  let dataIndex = null;

                  if (col.dataIndex) {
                    dataIndex = isArray(col.dataIndex)
                      ? col.dataIndex.join('.')
                      : `${col.dataIndex}`;
                  }

                  const content =
                    col.render?.(dataIndex ? get(record, dataIndex) : null, record, index) ||
                    // @ts-expect-error
                    record[dataIndex];

                  return {
                    key: col.key,
                    label: typeof col.title === 'function' ? col.title({}) : col.title,
                    children: instanceOfRenderedCell(content) ? content.children : content,
                    span: 3,
                    labelStyle: {
                      fontWeight: 'bold',
                      maxWidth: labelsWidth,
                      width: labelsWidth,
                    },
                  };
                })}
            />
          </td>
        </tr>
      )}
    </>
  );
};
