import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const IRDashboardReportSummaryItemSchema = z.object({
  act_recruiter_id: z.string(),
  recruiter_name: z.string(),
  stage: z.string(),
  first_pweek: z.number(),
  second_pweek: z.number(),
  third_pweek: z.number(),
  fourth_pweek: z.number(),
  current_week: z.number(),
  yesterday: z.number(),
  today: z.number(),
  tomorrow: z.number(),
  next_week: z.number(),
});
export type IRDashboardReportSummaryItem = z.infer<typeof IRDashboardReportSummaryItemSchema>;

export type IRDashboardReportSummaryResponse = IRDashboardReportSummaryItem[];

export type IRDashboardReportSummaryEndpoint = Endpoint<
  Method.GET,
  `/api/v2/interview-requests/dashboard-report-summary`,
  IRDashboardReportSummaryResponse,
  undefined,
  undefined,
  undefined
>;
