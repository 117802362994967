import React, { useState, useEffect } from 'react';
import { Popover, Button, Checkbox, Space, Tooltip } from 'antd';
import { TableOutlined } from '@ant-design/icons';
import { ColumnType } from './index';

type ToggleColumnsProps<T extends {}> = {
  tableId: string;
  columns?: ColumnType<T>[];
  onChange?: (value: string[]) => void;
};

export const getToggleId = <T extends {}>(column: ColumnType<T>, index: number): string => {
  if (column.key) {
    return `${column.key}`;
  }

  if (column.dataIndex) {
    return `${column.dataIndex}`;
  }

  return `column-${index}`;
};

const ToggleColumns = <T extends {}>({ tableId, columns, onChange }: ToggleColumnsProps<T>) => {
  const [selectedColumns, setSelectedColumns] = useState<string[]>([]);

  useEffect(() => {
    const toggledColumns = JSON.parse(localStorage.getItem('toggledColumns') || '{}');
    if (toggledColumns[tableId]) {
      setSelectedColumns(toggledColumns[tableId]);
      onChange?.(toggledColumns[tableId]);
    } else {
      setSelectedColumns(columns?.map((column, i) => getToggleId(column, i)) || []);
    }
  }, [tableId, columns, onChange]);

  const selectAll = () => {
    const allColumns = columns?.map((column, i) => getToggleId(column, i)) || [];

    setSelectedColumns(allColumns);
    onChange?.(allColumns);

    const toggledColumns = JSON.parse(localStorage.getItem('toggledColumns') || '{}');
    delete toggledColumns[tableId];
    localStorage.setItem('toggledColumns', JSON.stringify(toggledColumns));
  };

  const handleChange = (selected: string[]) => {
    setSelectedColumns(selected);
    onChange?.(selected);

    const toggledColumns = JSON.parse(localStorage.getItem('toggledColumns') || '{}');
    toggledColumns[tableId] = selected;
    localStorage.setItem('toggledColumns', JSON.stringify(toggledColumns));
  };

  const columnsAsOptions = columns?.map((column, i) => ({
    label: typeof column.title === 'function' ? column.title({}) : column.title,
    value: getToggleId(column, i),
  }));

  const content = (
    <Space direction="vertical" size={8}>
      <Checkbox.Group
        options={columnsAsOptions}
        value={selectedColumns}
        onChange={handleChange}
        style={{ flexDirection: 'column' }}
      />
      <Button style={{ width: '100%' }} onClick={selectAll}>
        Select All
      </Button>
    </Space>
  );

  return (
    <Popover content={content} trigger="click" placement="bottomRight">
      <Tooltip
        title={`To avoid horizontal scrolling you can uncheck column names to hide them, check to show them, and pick "Select All" to reset.  Your current selection is saved and applied the next time you go to his page.`}
      >
        <Button
          className={selectedColumns.length !== columns?.length ? 'btn-toggle-columns-active' : ''}
          icon={<TableOutlined />}
          data-testid="toggle-columns"
        >
          Hide columns
        </Button>
      </Tooltip>
    </Popover>
  );
};

export default ToggleColumns;
