import { z } from 'zod';

import type { Endpoint, Method } from '../../../../infrastructure';

export const CriteriaCorpWebhookBodySchema = z.object({
  orderId: z.string(),
  externalId: z.string().nullable(),
  candidate: z.object({
    first: z.string(),
    last: z.string(),
    email: z.string(),
    date: z.string(),
    eventId: z.string(),
    testTakerId: z.string(),
  }),
  scores: z
    .object({
      RankingScore: z.number().nullable(),
      EPPPercentMatch: z.number().nullable(),
      EPPInvalid: z.boolean().nullable(),
      EPPInconsistency: z.number().nullable(),
      EPPAchievement: z.number().nullable(),
      EPPMotivation: z.number().nullable(),
      EPPCompetitiveness: z.number().nullable(),
      EPPManagerial: z.number().nullable(),
      EPPAssertiveness: z.number().nullable(),
      EPPExtroversion: z.number().nullable(),
      EPPCooperativeness: z.number().nullable(),
      EPPPatience: z.number().nullable(),
      EPPSelfConfidence: z.number().nullable(),
      EPPConscientiousness: z.number().nullable(),
      EPPOpenness: z.number().nullable(),
      EPPStressTolerance: z.number().nullable(),

      Accounting: z.number().nullable(),
      AdminAsst: z.number().nullable(),
      Analyst: z.number().nullable(),
      BankTeller: z.number().nullable(),
      Collections: z.number().nullable(),
      CustomerService: z.number().nullable(),
      FrontDesk: z.number().nullable(),
      Manager: z.number().nullable(),
      MedicalAsst: z.number().nullable(),
      Production: z.number().nullable(),
      Programmer: z.number().nullable(),
      Sales: z.number().nullable(),

      CCATRawScore: z.number().nullable(),
      CCATPercentile: z.number().nullable(),
      CCATInvalidScore: z.number().nullable(),
      CCATSpatialPercentile: z.number().nullable(),
      CCATVerbalPercentile: z.number().nullable(),
      CCATMathPercentile: z.number().nullable(),
    })
    .optional(),
  reportUrl: z.string(),
  candidateReportUrl: z.string(),
  metAllScoreRanges: z.enum(['Yes', 'No', 'N/A']),
});

export type CriteriaCorpWebhookResponse = undefined;

export type CriteriaCorpWebhookParams = undefined;

export type CriteriaCorpWebhookQuery = undefined;

export type CriteriaCorpWebhookBody = z.infer<typeof CriteriaCorpWebhookBodySchema>;

export type CriteriaCorpWebhookEndpoint = Endpoint<
  Method.GET,
  '/api/v2/criteria-assessment/status-update',
  null,
  CriteriaCorpWebhookParams,
  CriteriaCorpWebhookQuery,
  CriteriaCorpWebhookBody
>;
