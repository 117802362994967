import { Endpoint, Method } from '../../../infrastructure';
import { InterviewCalendarEventSchema } from '../base';
import { z } from 'zod';

export const ICEGetPendingByApplicationItemSchema = InterviewCalendarEventSchema.extend({
  act_recruiter_name: z.string().nullable(),
}).nullable();
export type ICEGetPendingByApplicationItem = z.infer<typeof ICEGetPendingByApplicationItemSchema>;

export const ICEGetPendingByApplicationParamsSchema = z.object({
  application_id: z.string(),
});
export type ICEGetPendingByApplicationParams = z.infer<
  typeof ICEGetPendingByApplicationParamsSchema
>;

export type ICEGetPendingByApplicationResponse = ICEGetPendingByApplicationItem;

export type ICEGetPendingByApplicationEndpoint = Endpoint<
  Method.GET,
  `/api/v2/interview-calendar-events/pending-by-application/${ICEGetPendingByApplicationParams['application_id']}`,
  ICEGetPendingByApplicationResponse,
  ICEGetPendingByApplicationParams,
  undefined,
  undefined
>;
