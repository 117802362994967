import { useCallback, useState } from 'react';
import { BlobRequest, CSVRequest } from '@ct-internal/api';
import { message } from 'antd';

type DownloaderBlob = (fileName: string, blob: Blob) => void;
type DownloaderText = (fileName: string, text: string) => void;

const downloadText: DownloaderText = (filename, text) => {
  const element = document.createElement('a');
  element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
  element.setAttribute('download', filename);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

const downloadBlob: DownloaderBlob = (fileName, blob) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;

  a.click();
};

const useDownload = <T extends BlobRequest | CSVRequest>(
  loadFile: T,
  filename: string,
  downloadTextArg = downloadText,
  downloadBlobArg = downloadBlob,
) => {
  const [isLoading, setIsLoading] = useState(false);

  const download = useCallback(async () => {
    setIsLoading(true);
    try {
      const data = await loadFile();

      if (typeof data === 'string') {
        downloadTextArg(filename, data);
      } else {
        downloadBlobArg(filename, data);
      }
    } catch (e: unknown) {
      const errorMessage =
        e instanceof Error ? e.message : typeof e === 'string' ? e : 'Unknown error';
      message.error(errorMessage);
    }
    setIsLoading(false);
  }, [loadFile, downloadTextArg, downloadBlobArg, filename]);

  return { download, isLoading };
};

export default useDownload;
