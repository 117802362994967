import { useEffect, useMemo, useState } from 'react';
import { Form } from 'antd';
import {
  addFilter,
  createFilterFields,
  FilterSchema,
  getFilterDescriptor,
  InferFilterFieldValues,
  InferFilterQuery,
  toFilterValues,
} from '@ct-internal/api';

import { FiltersSetup } from '../../types';
import { validateSchemaValues } from '../../utils/validation';

export const useFilters = <T extends FilterSchema>(
  filterSchema: T,
  initialFilters: InferFilterQuery<T> = {},
): FiltersSetup<T> => {
  const [updatesCount, setUpdatesCount] = useState(0);
  const [form] = Form.useForm<InferFilterFieldValues<T>>();

  const initialFormattedValues = useMemo(
    () => toFilterValues<T>(initialFilters) as InferFilterFieldValues<T>,
    // Initialisation
    // eslint-disable-next-line
    [],
  );

  useEffect(() => {
    const onMountFormValues = form.getFieldsValue(true);
    if (Object.keys(initialFilters).length > 0 && Object.keys(onMountFormValues).length === 0) {
      // This handles case when there is no actual form in the dom
      form.setFieldsValue(initialFormattedValues);
    }
    // eslint-disable-next-line
  }, []);

  // Define filter values before ant initializes form
  const filterValues = updatesCount === 0 ? initialFormattedValues : form.getFieldsValue(true);

  const filters = useMemo(() => {
    return Object.entries(filterValues).reduce((result, [key, value]) => {
      const [field, operator] = getFilterDescriptor<InferFilterQuery<T>>(key);

      // Omit empty values
      if (value === '' || value === undefined) {
        return result;
      }

      return addFilter(result, field, operator, value as any);
    }, {} as InferFilterQuery<T>);
  }, [filterValues]);

  const errors = useMemo(
    () => validateSchemaValues(filterSchema, filters),
    [filterSchema, filters],
  );

  useEffect(() => {
    // Clean old errors
    const fieldsStatus = form
      .getFieldsError()
      // Filter out those that still a have error
      .filter(({ name }) => !errors.errors?.[name[0]])
      // Mark fields without error as valid
      .reduce((result, { name }) => {
        const [field] = name;
        result.push({
          name: field,
          errors: [],
        });
        return result;
      }, [] as Parameters<typeof form.setFields>[0]);

    // Add new errors
    const errorFields = Object.keys(errors.errors || {});
    errorFields.reduce((result, field) => {
      result.push({
        name: field,
        errors: [errors.errors?.[field] as string],
      });
      return result;
    }, fieldsStatus);
    form.setFields(fieldsStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errors]);

  const fields = useMemo(() => createFilterFields<T>(filterSchema), [filterSchema]);

  // This decorator helps us trigger state update when a field changes,
  // since ant doesn't re-renders state on setFieldValue.
  const forceUpdate = () => {
    setUpdatesCount((prevValue) => prevValue + 1);
  };
  const formDecorator = new Proxy(form, {
    get: (target, prop, parent) => {
      if (prop === 'setFieldsValue' || prop === 'setFieldValue' || prop === 'resetFields') {
        return (...args: any[]) => {
          // @ts-ignore
          Reflect.get(target, prop, parent)(...args);
          forceUpdate();
        };
      }
      return Reflect.get(target, prop);
    },
  });

  const ready = Object.keys(errors).length === 0;

  return {
    form: formDecorator,
    filters,
    fields,
    initialValues: initialFormattedValues,
    onValuesChange: forceUpdate,
    errors,
    ready,
  };
};
