import moment from 'moment';
import { Button } from 'antd';
import { ActionComponent } from '.';
import { useUpdateNotification } from '@src/queries/Notification';
import { CheckOutlined } from '@ant-design/icons';

import { useSendGtmEvent } from '@shared/gtm';

export const CloseNotification: ActionComponent = ({ notification }) => {
  const sendGtmEvent = useSendGtmEvent();
  const { mutateAsync: updateNotification } = useUpdateNotification();

  const markAsDone = () => {
    updateNotification({
      params: { action_request_id: notification.action_request_id },
      request: { dt_action_completed: moment().toISOString() },
    });

    sendGtmEvent('notification_marked_as_done');
  };

  return (
    <Button type="link" size="small" icon={<CheckOutlined />} onClick={markAsDone}>
      Mark as done
    </Button>
  );
};

export default CloseNotification;
