import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';
import { TargetGroup } from '../schemas';

export const CreateTargetGroupRequestSchema = z.object({
  name: z.string(),
  description: z.string().optional(),
  emails: z.array(z.string()),
});

export type CreateTargetGroupRequest = z.infer<typeof CreateTargetGroupRequestSchema>;
export type CreateTargetGroupResponse = TargetGroup;

export type CreateTargetGroupEndpoint = Endpoint<
  Method.POST,
  '/api/coach-ai/target-groups',
  CreateTargetGroupResponse,
  {},
  {},
  CreateTargetGroupRequest
>;
