import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const ListPeopleMissingCheckinResponseItemSchema = z.object({
  email: z.string(),
  slackId: z.string(),
  last_checkin_time: z.string().datetime(),
});
export type ListPeopleMissingCheckinResponseItem = z.infer<
  typeof ListPeopleMissingCheckinResponseItemSchema
>;

export type ListPeopleMissingCheckinResponse = {
  items: ListPeopleMissingCheckinResponseItem[];
};

export const ListPeopleMissingCheckinQuerySchema = z.object({
  target_groups: z.array(z.string()).optional(),
  since_days: z.coerce.number().default(7),
});
export type ListPeopleMissingCheckinQuery = z.infer<typeof ListPeopleMissingCheckinQuerySchema>;

export type ListPeopleMissingCheckinEndpoint = Endpoint<
  Method.GET,
  '/api/coach-ai/people-missing-checkin',
  ListPeopleMissingCheckinResponse,
  {},
  ListPeopleMissingCheckinQuery,
  {}
>;
