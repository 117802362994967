import React from 'react';
import { FilterOutlined, CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import './index.css';

export type FilterButtonProps = {
  filterLabel?: string;
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
};

const FilterButton = ({ filterLabel, open, setOpen }: FilterButtonProps) => {
  return (
    <div className="button-filter-wrapper">
      <div className="filter-button">
        <div className="button-position">
          <div
            className={`button-content ${open ? 'open' : ''}`}
            onClick={() => setOpen && setOpen((prev) => !prev)}
          >
            <FilterOutlined />
            <span className="button-text">{filterLabel}</span>
            {open ? <CaretUpOutlined /> : <CaretDownOutlined />}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterButton;
