import { z } from 'zod';
import { Endpoint, FilterOperations, Method, dateString, makeSorter } from '../../infrastructure';

export const AsnMarginMonthlyReportItemSchema = z.object({
  ymonth: z.number(),
  person_id: z.string(),
  assignment_id: z.string(),
  project_id: z.string(),
  position_id: z.string(),
  dtstart: dateString,
  dtend: dateString,

  person_name: z.string(),
  gravatar_url: z.string(),
  position_name: z.string(),
  project_name: z.string(),
  client_name: z.string(),

  allocated_cost_days: z.number(),
  avg_cost_days: z.number(),
  allocated_cost: z.number(),
  cost_reference: z.number(),

  allocated_rev_days: z.number(),
  avg_rev_days: z.number(),
  allocated_rev: z.number(),
  revenue_reference: z.number(),
  adjustedrate: z.number(),

  profit: z.number(),
  margin: z.number(),
});
export type AsnMarginMonthlyReportItem = z.infer<typeof AsnMarginMonthlyReportItemSchema>;

export const AsnMarginMonthlyReportFilterSchema = z.object({
  ymonth: z.record(z.enum([FilterOperations.EQUAL]), z.coerce.number()).optional(),
  project_id: z.record(z.enum([FilterOperations.EQUAL]), z.string()).optional(),
});
export type AsnMarginMonthlyReportFilter = z.infer<typeof AsnMarginMonthlyReportFilterSchema>;

export const AsnMarginMonthlyReportSorterSchema = makeSorter(
  z.enum([
    'person_name',
    'position_name',
    'dtstart',
    'dtend',
    'allocated_cost_days',
    'allocated_cost',
    'cost_reference',
    'allocated_rev_days',
    'allocated_rev',
    'revenue_reference',
    'profit',
    'margin',
  ]),
);
export type AsnMarginMonthlyReportSorter = z.infer<typeof AsnMarginMonthlyReportSorterSchema>;

export const AsnMarginMonthlyReportQuerySchema = z.object({
  filters: AsnMarginMonthlyReportFilterSchema.optional(),
  sorter: AsnMarginMonthlyReportSorterSchema.optional(),
});
export type AsnMarginMonthlyReportQuery = z.infer<typeof AsnMarginMonthlyReportQuerySchema>;

export type AsnMarginMonthlyReportResponse = AsnMarginMonthlyReportItem[];

export type AsnMarginMonthlyReportEndpoint = Endpoint<
  Method.GET,
  `/api/v2/sql-reports/asn-margin-monthly`,
  AsnMarginMonthlyReportResponse,
  undefined,
  AsnMarginMonthlyReportQuery,
  undefined
>;
