import { z } from 'zod';
import {
  FilterOperations,
  FilterSchema,
  FiltersQuery,
  InferFilterQuery,
  isCompositeFilter,
  isObjectFilter,
} from '../query';

const OperationDelimiter = '_OPERATION_';
type OPERATION_DELIMITER = typeof OperationDelimiter;
type OPERATION_NAME<
  KEY extends string,
  OPERATION extends FilterOperations,
> = `${KEY}${OPERATION_DELIMITER}${OPERATION}`;

export type FilterDescriptor<T extends FiltersQuery> = [keyof T, InferFilterOperations<T, keyof T>];

export type InferFilterOperations<T extends FiltersQuery, K extends keyof T> = T[K] extends
  | infer U
  | undefined
  ? keyof U
  : never;

export type InferFilterFieldValues<T extends FilterSchema> = Required<{
  [key in keyof z.infer<T>]: {
    [operation in keyof (z.infer<T>[key] extends infer U | undefined ? U : never)]: {
      // eslint-disable-next-line no-unused-vars
      [field in OPERATION_NAME<
        key extends string ? key : never,
        operation extends FilterOperations ? operation : never
      >]: z.infer<T>[key][operation];
    };
  };
}> extends { [key: string]: infer U }
  ? U extends { [key: string]: infer K }
    ? Partial<K>
    : never
  : never;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void
  ? I
  : never;

export type InferFilterFields<T extends FilterSchema> = Required<{
  [key in keyof UnionToIntersection<z.infer<T>>]: Required<{
    [operation in keyof (UnionToIntersection<z.infer<T>>[key] extends infer U | undefined
      ? U
      : never)]: OPERATION_NAME<
      key extends string ? key : never,
      operation extends FilterOperations ? operation : never
    >;
  }>;
}>;

export type InferFilterFieldsNames<T extends FilterSchema> = InferFilterFields<T> extends {
  [key: string]: infer U;
}
  ? U extends { [key: string]: infer K }
    ? K
    : never
  : never;

export const createFilterFieldName = <T extends string, K extends FilterOperations>(
  name: T,
  operation: K,
) => `${name}${OperationDelimiter}${operation}` as OPERATION_NAME<T, K>;

export const unwrapOptional = (object: any): any => (object.unwrap ? object.unwrap() : object);

export const createFilterFields = <T extends FilterSchema>(
  filterSchema: T,
  result = {} as InferFilterFields<T>,
) => {
  if (isObjectFilter(filterSchema)) {
    return Object.entries(filterSchema.shape).reduce((filterFields, entry) => {
      const key = entry[0] as keyof typeof filterFields;
      const operations = unwrapOptional(entry[1])._def.keyType._def.values as FilterOperations[];
      filterFields[key] = {} as (typeof filterFields)[typeof key];
      operations.forEach((operation) => {
        filterFields[key][operation as keyof (typeof filterFields)[typeof key]] =
          createFilterFieldName(key as string, operation) as OPERATION_NAME<any, any>;
      });
      return filterFields;
    }, result);
  } else if (isCompositeFilter(filterSchema)) {
    filterSchema._def.options.forEach((schema) => createFilterFields(schema as T, result));
    return result;
  } else {
    throw new Error(`Unknown filter schema ${filterSchema}`);
  }
};

export const toFilterValues = <T extends FilterSchema>(filters: InferFilterQuery<T>) => {
  return Object.entries(filters).reduce((result, [key, operations]) => {
    Object.entries(operations).forEach(([operation, value]) => {
      const filterName = createFilterFieldName(key, operation as FilterOperations);
      // @ts-ignore - ts has trouble resolving this type
      result[filterName] = value;
    });
    return result;
  }, {} as InferFilterFieldValues<T>);
};

export const getFilterDescriptor = <T extends FiltersQuery>(filedName: string) =>
  filedName.split(OperationDelimiter) as FilterDescriptor<T>;

export const addFilter = <T extends FiltersQuery, K extends keyof T>(
  oldFilters: T,
  field: K,
  operation: keyof (T[K] extends infer U | undefined ? U : never),
  value: T[K] extends Record<any, infer U> | undefined ? U : never,
) =>
  ({
    ...oldFilters,
    [field]: {
      ...(oldFilters[field] || {}),
      [operation]: value,
    },
  } as T);

export const removeFilter = <T extends FiltersQuery, K extends keyof T>(
  oldFilters: T,
  field: K,
  operation: keyof (T[K] extends infer U | undefined ? U : never),
): Omit<T, K> => {
  const newFilters = { ...oldFilters };

  const fieldFilters = { ...(oldFilters[field] || {}) };
  delete fieldFilters[operation as keyof typeof fieldFilters];

  if (Object.keys(fieldFilters).length > 0) {
    newFilters[field] = fieldFilters;
  } else {
    delete newFilters[field];
  }

  return newFilters;
};
