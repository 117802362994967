import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { ICEInitiateGoogleEventRequest, InterviewRequestStage } from '@ct-internal/api';
import { useICEPendingByApplication } from '@src/queries/InterviewCalendarEvent';
import { useICEInitiateGoogleEvent } from '@src/queries/InterviewCalendarEvent/mutations';
import { InputNumber, Button, Space, Select, DatePicker, Form, Card, Modal } from 'antd';

import get from 'lodash/get';
import map from 'lodash/map';
import isNil from 'lodash/isNil';
import isEmpty from 'lodash/isEmpty';
import filter from 'lodash/filter';

import moment from 'moment';
import * as applicationSelectors from '@selectors/application';
import * as applicationActions from '@redux/modules/application/application';

export interface IGoogleEventModal {
  isReschedule?: boolean;
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  application_id: string;
}

type FormData = {
  dt_start: dayjs.Dayjs;
  duration: number;
  stage: InterviewRequestStage;
  recruiter_email: string;
};

export function GoogleEventModal(props: IGoogleEventModal) {
  const { onClose, onSuccess, isOpen, application_id, isReschedule } = props;
  const dispatch = useDispatch();

  const user = useSelector((state: any) => state?.authentication?.user) ?? {};
  const recruitersUsers = useSelector(applicationSelectors.getRecruitersList);

  const [form] = Form.useForm<FormData>();
  const stageOptions = map(Object.keys(InterviewRequestStage), (key) => ({
    label: get(InterviewRequestStage, key),
    value: key,
  }));

  const usersOptions = map(filter(recruitersUsers?.data ?? [], { active: true }), (item) => ({
    label: item.name,
    value: item.email,
  }));

  useEffect(() => {
    if (isEmpty(recruitersUsers) || isNil(recruitersUsers)) {
      dispatch(applicationActions.actions.getRecruitersList());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recruitersUsers]);

  const { mutateAsync, isLoading } = useICEInitiateGoogleEvent({
    onSuccess: () => {
      form.resetFields();
      onSuccess();
      onClose();
    },
  });

  const { data: pendingICE } = useICEPendingByApplication({
    params: {
      application_id,
    },
  });
  const existsPendingICE = useMemo(() => !isNil(pendingICE), [pendingICE]);

  const handleSubmit = async () => {
    const formValues = await form.getFieldsValue();

    const tmFm = 'YYYY-MM-DD HH:mm:ss';
    const dt_start = moment(formValues.dt_start.format(tmFm), tmFm);

    const dt_end = dt_start.clone().add(formValues.duration, 'minute');

    const dto: ICEInitiateGoogleEventRequest = {
      application_id,
      recruiter_email: formValues.recruiter_email,
      dt_start: dt_start.format(tmFm),
      dt_end: dt_end.format(tmFm),
      stage: formValues.stage,
      exists_pending: existsPendingICE ?? false,
    };

    if (existsPendingICE && !isReschedule) {
      return Modal.confirm({
        title: 'Application have open Interview Request',
        content:
          'This application have open Interview Request, the current action will result in a Reschedule action, do you want to proceed ?',
        onOk: async () =>
          mutateAsync({
            request: dto,
          }),
        onCancel: () => handleCancel(),
      });
    }

    return mutateAsync({
      request: dto,
    });
  };

  const handleCancel = () => {
    form.resetFields();
    onClose();
  };

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Modal open={isOpen} onCancel={handleCancel} footer={null}>
        <Card
          title={isReschedule ? 'Reschedule the interview' : 'Booking new interview'}
          loading={isLoading}
        >
          <Form
            form={form}
            onFinish={handleSubmit}
            initialValues={{
              stage: InterviewRequestStage['1st'],
              duration: 30,
              recruiter_email: user.email,
            }}
          >
            <Form.Item
              name="dt_start"
              label={
                <>
                  Start <b>(EST)</b>
                </>
              }
              rules={[{ required: true, message: 'Interview range is required' }]}
            >
              <DatePicker
                disabledDate={(e) =>
                  moment(e.format('YYYY-MM-DD'), 'YYYY-MM-DD').isBefore(moment().startOf('day'))
                }
                showTime={{
                  format: 'HH:mm',
                }}
              />
            </Form.Item>
            <Form.Item
              name="duration"
              label="Duration"
              rules={[{ required: true, message: 'Interview duration is required' }]}
            >
              <InputNumber min={15} max={60} />
            </Form.Item>
            {!isReschedule && (
              <Form.Item
                name="stage"
                label="Stage"
                rules={[{ required: true, message: 'Stage of interview is required' }]}
              >
                <Select
                  options={stageOptions}
                  defaultValue={InterviewRequestStage['1st']}
                  style={{ maxWidth: '100px' }}
                />
              </Form.Item>
            )}
            <Form.Item
              name="recruiter_email"
              label="Interviewer"
              rules={[{ required: true, message: 'Interviewer is required' }]}
            >
              <Select
                showSearch
                options={usersOptions}
                filterOption={(input, option) =>
                  option?.label?.toUpperCase().indexOf(input.toUpperCase()) !== -1
                }
                defaultValue={InterviewRequestStage['1st']}
                style={{ maxWidth: '200px' }}
              />
            </Form.Item>
            <Space direction="horizontal">
              <Form.Item>
                <Button onClick={handleCancel}>Cancel</Button>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Book Interview
                </Button>
              </Form.Item>
            </Space>
          </Form>
        </Card>
      </Modal>
    </div>
  );
}

export default GoogleEventModal;
