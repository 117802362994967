import { z } from 'zod';
import { Endpoint, Method } from '../../../infrastructure';

export const ApplicationSetExecutivePresenceRequestSchema = z.object({
  executive_presence: z.boolean().default(false),
});

export type ApplicationSetExecutivePresenceRequest = z.infer<
  typeof ApplicationSetExecutivePresenceRequestSchema
>;

export const ApplicationSetExecutivePresenceParamsSchema = z.object({
  application_id: z.string(),
});
export type ApplicationSetExecutivePresenceParams = z.infer<
  typeof ApplicationSetExecutivePresenceParamsSchema
>;

export type ApplicationSetExecutivePresenceResponse = any;

export type ApplicationSetExecutivePresenceEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/applications/${ApplicationSetExecutivePresenceParams['application_id']}/set-executive-presence`,
  ApplicationSetExecutivePresenceResponse,
  ApplicationSetExecutivePresenceParams,
  undefined,
  ApplicationSetExecutivePresenceRequest
>;
