import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { Layout, Row, Col, Button, Alert, Typography, Grid } from 'antd';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
// eslint-disable-next-line import/no-extraneous-dependencies
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { GoogleOAuthProvider } from '@react-oauth/google';

import * as Sentry from '@sentry/react';
import ErrorBoundaryFallback from './ErrorBoundaryFallback/ErrorBoundaryFallback';

import { actions as authActions } from '@redux/modules/authentication';
import { actions as applicationActions } from '@redux/modules/application/application';

import history from '@src/history';
import config from '@src/config';
import MainMenu from '@components/MainMenu/MainMenu';
import QuickSearch from '@components/MainMenu/QuickSearch';
import NotificationBell from '@components/Notification/NotificationBell';

import '@styles/App.less';

import { notifyNewAppVersion } from '@shared/new-version-notification';
import { useSocketListener } from '@shared/hooks';
import { routerNavigationData } from '@src/navigation-routes';
import Announcements from '@ct-internal/ui/src/components/Announcements';

let searchActions;
import('@redux/search').then(({ actions }) => (searchActions = actions));

const { Header, Content, Footer } = Layout;
const Search = QuickSearch; //Input.Search;
const queryClient = new QueryClient();

const { useBreakpoint } = Grid;

// eslint-disable-next-line max-statements
const App = (props) => {
  const screens = useBreakpoint();
  const isResponsive = screens.xs || (screens.sm && !screens.md) || (screens.md && !screens.lg);
  const dispatch = useDispatch();
  const location = useLocation();

  const hireLogo =
    process.env.NODE_ENV === 'development' ? '/ct_logo_hire_dev.svg' : '/ct_logo_hire.svg';

  // local state
  const [query] = useState('');
  const [isMenuVisible, setIsMenuVisible] = useState(!isResponsive);
  const [isFocused, setIsFocused] = useState(false);

  // redux state
  const user = useSelector((state) => state.authentication.user);

  // methods
  const renderContent = () => {
    return props.children;
  };

  const toggleMenuVisible = () => {
    setIsMenuVisible(!isMenuVisible);
  };

  const onMenuItemClick = () => {
    setIsMenuVisible(false);
  };

  const onRouteChanged = (location, action) => {
    // eslint-disable-next-line no-console
    console.log('App - Route Changed', {
      action,
      pathname: location.pathname,
      state: location.state,
    });

    if (applicationActions) {
      dispatch(applicationActions.deleteError());
    }

    if (searchActions) {
      dispatch(searchActions.deleteError());
    }
  };

  const login = (data) => {
    dispatch(authActions.login(data));
  };

  const loginWithToken = (data) => {
    dispatch(authActions.loginWithToken(data));
  };

  const logout = () => {
    dispatch(authActions.logout());
  };

  // hooks
  useEffect(() => {
    const handleResize = () => {
      if (!isResponsive) {
        setIsMenuVisible(true);
      } else {
        setIsMenuVisible(false);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isResponsive]);

  useEffect(() => {
    const unlistenHistory = history.listen(onRouteChanged);
    return () => {
      unlistenHistory();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // eslint-disable-next-line no-console
    console.log('NODE_ENV:', process.env.NODE_ENV);
  }, []);

  // websockets
  useSocketListener('new-version-deploy-hire', (event) => {
    notifyNewAppVersion();
  });

  const handleFocus = () => {
    setIsFocused(true);
    if (isResponsive) {
      setIsMenuVisible(false);
    }
  };

  const handleBlur = () => {
    setIsFocused(false);
  };

  // render
  const { pathname } = location;
  const year = new Date().getFullYear();
  const footerMsg = `Clevertech ©${year}`;

  return (
    <GoogleOAuthProvider clientId={config.GOOGLE_CLIENT_ID}>
      <QueryClientProvider client={queryClient}>
        <Layout>
          <Helmet defaultTitle="CleverHire" titleTemplate="%s | CleverHire" />
          <Header className="top-bar">
            <Row justify="start" align="middle" wrap={false}>
              <Col lg={0} md={{ flex: 'none' }} sm={{ flex: 'none' }} xs={{ flex: 'none' }}>
                <Button onClick={toggleMenuVisible} type="primary" style={{ marginRight: '2rem' }}>
                  {isMenuVisible ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                </Button>
              </Col>
              {(!isResponsive || !isFocused) && (
                <Col flex={'none'}>
                  <Link to="/" style={{ height: '64px', display: 'flex', alignItems: 'center' }}>
                    <img
                      src={hireLogo}
                      alt=""
                      style={{
                        marginTop: process.env.NODE_ENV === 'development' ? '10px' : 0,
                        marginRight: '2rem',
                        height: '39px',
                      }}
                    />
                  </Link>
                </Col>
              )}
              <Col
                lg={{ span: 10, order: 3 }}
                md={{ span: isMenuVisible ? 24 : 0, order: 4 }}
                sm={{ span: isMenuVisible ? 24 : 0, order: 4 }}
                xs={{ span: isMenuVisible ? 24 : 0, order: 4 }}
                style={isResponsive ? { position: 'absolute', top: 64 } : {}}
              >
                <MainMenu
                  login={login}
                  loginWithToken={loginWithToken}
                  logout={logout}
                  location={pathname}
                  onMenuItemClick={onMenuItemClick}
                  style={{
                    lineHeight: '64px',
                    flex: '1 1 auto',
                  }}
                />
              </Col>
              <Col
                className={'search-bar-style'}
                lg={{ flex: 'auto' }}
                md={{ flex: 'auto', order: 3 }}
                sm={{ flex: 'auto', order: 3 }}
                xs={{ flex: 'auto', order: 3 }}
              >
                <div className="search-bar-style">
                  {user && user.isAdmin && (
                    <Search
                      placeholder="California, iOS,..."
                      defaultValue={query}
                      enterButton
                      onFocus={handleFocus}
                      onBlur={handleBlur}
                    />
                  )}
                </div>
              </Col>
              {user && (
                <Col flex={'none'} order={4}>
                  <NotificationBell />
                </Col>
              )}
              {user && (
                <Col flex={'none'} order={5} style={{ display: 'flex', alignItems: 'center' }}>
                  <Announcements app="hire" navigationData={routerNavigationData} />
                </Col>
              )}
            </Row>
          </Header>
          <Sentry.ErrorBoundary
            fallback={({ resetError }) => <ErrorBoundaryFallback resetError={resetError} />}
          >
            <Content className="content-wrapper">
              {process.env.NODE_ENV === 'development' && (
                <Alert
                  message={
                    <Typography.Text>
                      You are at the development website. Changes made here will not be reflected in
                      the{' '}
                      <Typography.Link href="https://hire.clevertech.biz/">
                        production environment
                      </Typography.Link>
                      .
                    </Typography.Text>
                  }
                  banner
                  showIcon={false}
                  closable
                  style={{ padding: '2.5rem 3rem', margin: '3rem 0' }}
                />
              )}
              {renderContent()}
            </Content>
          </Sentry.ErrorBoundary>
          <Footer style={{ textAlign: 'center', backgroundColor: '#f6f8ff' }}>{footerMsg}</Footer>
        </Layout>
        {process.env.NODE_ENV === 'development' && <ReactQueryDevtools initialIsOpen={false} />}
      </QueryClientProvider>
    </GoogleOAuthProvider>
  );
};

export default App;
