import { Endpoint, Method } from '../../../infrastructure';
import { InterviewCalendarEventSchema } from '../base';
import { z } from 'zod';

export const ICEFindByInterviewRequestItemSchema = InterviewCalendarEventSchema;
export type ICEFindByInterviewRequestItem = z.infer<typeof ICEFindByInterviewRequestItemSchema>;

export const ICEFindByInterviewRequestParamsSchema = z.object({
  interview_request_id: z.coerce.number(),
});
export type ICEFindByInterviewRequestParams = z.infer<typeof ICEFindByInterviewRequestParamsSchema>;

export type ICEFindByInterviewRequestResponse = ICEFindByInterviewRequestItem[];

export type ICEFindByInterviewRequestEndpoint = Endpoint<
  Method.GET,
  `/api/v2/interview-calendar-events/by-interview-request/${ICEFindByInterviewRequestParams['interview_request_id']}`,
  ICEFindByInterviewRequestResponse,
  ICEFindByInterviewRequestParams,
  undefined,
  undefined
>;
