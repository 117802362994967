import _isObject from 'lodash/isObject';
import _isString from 'lodash/isString';
import config from './config';
import qs from 'qs';

import { getUser, store } from '@redux/store';

// Local development setup
import https from 'https';

const { API_BASE } = config;
const { actions } = require('@redux/modules/authentication');

const _getPath = async (path: string, opts: any) => {
  try {
    const parsedUrl = /(.*)(\?.*)/.exec(path);
    let urlPath = path;
    let urlQuery = '';
    if (parsedUrl) {
      [, urlPath, urlQuery] = parsedUrl;
    }

    const queryParams = urlQuery ? qs.parse(urlQuery, { ignoreQueryPrefix: true }) : {};

    const params = { ...(opts.qs || opts.params || {}) };

    const queryString = qs.stringify(
      {
        ...queryParams,
        ...params,
      },
      {
        addQueryPrefix: true,
        encode: opts.encode || false,
        arrayFormat: 'comma',
        ...(opts.qsOptions || {}),
      },
    );

    return urlPath + queryString;
  } catch (err) {
    console.error('_getPath err', err);
    return path;
  }
};

const _makeRequest = async (path: string, options = {}, baseUrl: string = API_BASE) => {
  const opts = getPayload(options);

  const _path = await _getPath(path, opts);
  const requestUrl = `${baseUrl}/api/v2${_path}`;

  const response = await fetch(requestUrl, opts);

  return response;
};

const request = async (path: string, options: any = {}, baseUrl = API_BASE) => {
  const response = await _makeRequest(path, options, baseUrl);
  const json = options.asRaw || response.status === 204 ? response : await jsonBody(response);

  if (response.status === 401 && isLoggedIn()) {
    store.dispatch(actions.logout());
    setTimeout(() => {
      store.dispatch(actions.login());
    }, 1000);
    throw createError(json);
  }

  if (!response.ok) {
    throw createError(json);
  }

  return json;
};

function getPayload(options: any = {}) {
  // Stringify body option for fetch()
  const { body } = options;
  const isJson = Object.prototype.toString.call(body) === '[object Object]';

  // (DEV) Ignored self-signed cert
  const agent = new https.Agent({ rejectUnauthorized: config.ENV !== 'local' });

  let headers = isJson ? { 'Content-Type': 'application/json' } : {};

  headers = Object.assign(headers, options.headers, {
    Authorization: getAuthorization(options),
  });

  const extras = isJson ? { body: JSON.stringify(body) } : {};
  const result = Object.assign({}, options, { headers }, extras, { agent });

  return result;
}

const isLoggedIn = () => {
  return getUser();
};

function createError(data: any) {
  if (_isString(data.message)) {
    return data.message;
  } else if (_isObject(data.error)) {
    return data.error.message;
  } else if (_isString(data.error)) {
    return data.error;
  } else {
    return 'Unexpected error';
  }
}

const getAuthorization = ({ authorizationHeader }: { authorizationHeader?: string } = {}) => {
  const user = getUser();
  const token = user?.accessToken;
  return authorizationHeader || (token ? `JWT ${token}` : '');
};

const jsonBody = async (response: Response) => {
  try {
    return response.json();
  } catch (err) {
    console.warn('The server did not send a JSON response', err);
    return {};
  }
};

export { getAuthorization };
export default request;
