import { useCallback, useMemo, useState } from 'react';
import {
  InferSorter,
  InferSorterKeys,
  InferSorterReadonly,
  SortDirection,
  SorterQuery,
  SorterSchema,
} from '@ct-internal/api';
import { SorterMap, SorterMapKeys, SorterMapped } from '../types';

type UpdateSorter<T extends SorterSchema, K extends string | void = void> = (
  key: K extends void ? InferSorterKeys<T> : InferSorterKeys<T> | K,
  order: SortDirection | undefined,
) => void;

type UseSorterReturn<T extends SorterSchema, U extends SorterMap<any, any> | void = void> = [
  InferSorter<T>,
  UpdateSorter<T, U extends void ? void : SorterMapKeys<U>>,
];

type Writeable<T> = { -readonly [P in keyof T]: T[P] };

export const mapSorter = <U extends SorterMap<any, any>, T extends SorterMapped<any, U>>(
  sorterMap: U,
  sorter: T,
): SorterQuery => {
  return sorter.reduce((newSorter, sort) => {
    const [key, order] = sort;
    const sortMappedKeys = sorterMap[key];
    if (sortMappedKeys) {
      sortMappedKeys.forEach((key) => {
        newSorter.push([key, order]);
      });
    } else {
      newSorter.push(sort as Writeable<typeof sort>);
    }
    return newSorter;
  }, [] as SorterQuery);
};

export const updateSort = <T extends any[]>(
  sorter: T,
  newKey: any,
  newDirection: SortDirection | undefined,
): T => {
  const newSorter = [] as unknown as T;
  if (!newDirection) {
    // Delete undefined value
    return newSorter;
  }
  newSorter.push([newKey, newDirection]);
  // Updating order
  return newSorter;
};

/**
 * Works with single sort field at this moment.
 * If needed, change updateSort to be able to sort by multiple fields.
 */
export const useSorter = <T extends SorterSchema>(
  sorterSchema: T,
  // has to be readonly because of the way initial sorter is defined
  // with "as const" to get string literal types.
  initialSorter?: InferSorterReadonly<T>,
): UseSorterReturn<T> => {
  const [sorter, setSorter] = useState((initialSorter || []) as unknown as InferSorter<T>);

  const updateSorter: UpdateSorter<T> = useCallback(
    (key: InferSorterKeys<T>, order?: SortDirection) => {
      setSorter((currentSorter) => updateSort(currentSorter, key, order));
    },
    [],
  );

  return [sorter, updateSorter];
};

export const useMappedSorter =
  <T extends SorterSchema>(sorterSchema: T) =>
  <M extends string, N extends InferSorterKeys<T>>(
    sorterMap: SorterMap<M, N>,
    initialSorter: SorterMapped<T, typeof sorterMap>,
  ) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const [sorter, updateSorter] = useSorter(
      sorterSchema,
      initialSorter as unknown as InferSorterReadonly<T>,
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const mappedSorter = useMemo(
      () => mapSorter(sorterMap, sorter as unknown as typeof initialSorter) as InferSorter<T>,
      [sorter, sorterMap],
    );

    return [mappedSorter, updateSorter] as UseSorterReturn<T, typeof sorterMap>;
  };
