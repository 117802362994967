import { z } from 'zod';
import { dateString } from '../../infrastructure/schema';
import { HireGroup, PositionSchema } from './../positions/index';
export enum ConfidenceLevel {
  'INCLUDE_MIN' = 'INCLUDE_MIN',
  'INCLUDE_LIKELY' = 'INCLUDE_LIKELY',
  'INCLUDE_MAX' = 'INCLUDE_MAX',
  'EXCLUDE_ALL' = 'EXCLUDE_ALL',
}
export const ProjectSchema = z.object({
  //Identification information
  project_id: z.string(),
  client_id: z.string(),
  project_serial_id: z.number(),

  //General information
  short_name: z.string().nullable(),
  department: z.string(),
  isactive: z.boolean(),
  isstaffit: z.boolean(),
  islead: z.boolean(),
  isarchived: z.boolean(),
  lead_archived_date: dateString.nullable(),
  name: z.string(),
  dtsigned: dateString,
  dtplannedstart: dateString,
  dtplannedend: dateString,
  dtcreate: dateString,
  dtupdate: dateString,
  end_date_certainty: z.string(),
  plan_revenue_comment: z.string(),
  plan_revenue_comment_dtupdate: dateString,
  drive_folder_id: z.string(),
  hard_date: z.boolean().nullable(),
  hire_group: z.nativeEnum(HireGroup).nullable(),
  duration: z.number().nullable(),
  timezone: z.string().nullable(),
  expected_team_size: z.number().nullable(),
  expected_monthly_revenue: z.number().nullable(),
  expected_monthly_cost: z.number().nullable(),
  expected_monthly_margin: z.number().nullable(),
  equipment_needed: z.string().nullable(),
  signed_contract_url: z.string().nullable(),
  manage_team: z.boolean(),
  is_plan_included: z.boolean().default(false),
  is_pre_opportunity: z.boolean(),
  duration_weeks: z.number().nullable(),
  is_draft_opportunity: z.boolean().default(false),
  is_starter_project: z.boolean().nullable(),
  //SF
  project_sf_id: z.string().nullable(),
  sf_stage: z.string().nullable(),
  sf_description: z.string().nullable(),
  sf_description_updated_at: dateString.or(z.number()).nullish(),
  sf_notes_updated_at: dateString.or(z.number()).nullish(),
  sf_expected_monthly_revenue: z.number().nullable(),
  sf_locked_logs: z.string().nullable(),
  is_sf_locked: z.boolean().nullable(),
  owner_id: z.string().nullable(),
  confidence_level: z.nativeEnum(ConfidenceLevel).nullable(),

  //Billing informations
  isbillasteam: z.boolean(),
  isbillinadvance: z.boolean(),
  billday: z.number(),
  termdays: z.number(),
  billing_frequency: z.string(),
  isbillable: z.boolean(),
  billing_notes: z.string(),
  ignorevacations: z.boolean(),
  alternate_names: z.string(),

  //Invoicing informations
  inv_use_name: z.string(),
  is_export_as_one_line: z.boolean(),
  no_cost_allocation: z.boolean(),

  dt_archived: dateString.nullable(),
  dt_opp_extension: dateString.nullable(),
  previous_end_dates: z.array(dateString).nullable(),
});
export type Project = z.infer<typeof ProjectSchema>;

export enum ProjectBillingMode {
  'In advance' = 'In advance',
  'In arrears' = 'In arrears',
}

export const ProjectMongoSchema = z.object({
  _id: z.string(),
  client: z.string(),
  name: z.string(),
  shortName: z.string().nullish(),
  slug: z.string().nullish(),
  firstAuthor: z.string().nullable(),
  lastAuthor: z.string().nullable(),
  owner: z.string().nullish(),
  hireGroup: z.string().nullish(),
  isSigned: z.boolean().default(false).nullable(),
  isBillable: z.boolean().default(true).nullable(),
  isArchived: z.boolean().default(false).nullable(),
  billingMode: z
    .nativeEnum(ProjectBillingMode)
    .default(ProjectBillingMode['In arrears'])
    .nullable(),
  isBillAsTeam: z.boolean().nullable(),
  billDay: z.number().max(30).nullable(),
  termDays: z.number().nullable(),
  ignoreVacations: z.boolean().nullable(),
  startDate: dateString,
  endDate: dateString,
  positions: z.array(PositionSchema),
  expectedStartDate: z.string().nullish(),
  expectedStartMonth: z.string().nullish(),
  expectedTeamSize: z.number().nullish(),
  expectedMonthlyRevenue: z.number().nullish(),
  duration: z.number().nullish(),
  hardDate: z.boolean().default(true),
  timezone: z.string().nullish(),
  inv_use_name: z.string(),
  isExportAsOneLine: z.boolean().default(false).nullable(),
  plan_revenue_comment: z.string().nullable(),
  plan_revenue_comment_dtupdate: z.string().nullable(),
  isStaffIt: z.boolean().nullable(),
  expectedDurationInMonths: z.number().nullable(),
  created_at: z.string().nullable(),
  updated_at: z.string().nullable(),
  confidence: z.string().nullable(),
  __v: z.number().nullish(),
  deleted: z.boolean().nullish(),
  expected_monthly_cost: z.number().nullish(),
  project_sf_id: z.string().nullish(),
  sf_stage: z.string().nullish(),
  sf_description: z.string().nullish(),
  owner_id: z.string().nullish(),
  dt_archived: dateString.nullish(),
  signedContractURL: z.string().nullish(),
  manageTeam: z.boolean(),
  equipmentNeeded: z.string().nullish(),
  sf_expected_monthly_revenue: z.number().nullish(),
  is_draft_opportunity: z.boolean().nullish(),
});

export type ProjectMongo = z.infer<typeof ProjectMongoSchema>;

export enum TimezoneDisplay {
  'pst' = 'PST -3',
  'mst' = 'MST -2',
  'cst' = 'CST -1',
  'est' = 'EST',
  'cet' = 'CET +5',
  'utc' = 'UTC +5',
  'eet' = 'EET +7',
  'ist' = 'IST +10',
  'cst_china' = 'CST +13',
  'jst' = 'JST +14',
}

export enum TimezoneNames {
  'utc' = 'Coordinated Universal Time',
  'est' = 'Eastern Standard Time',
  'cst' = 'Central Standard Time',
  'mst' = 'Mountain Standard Time',
  'pst' = 'Pacific Standard Time',
  'cet' = 'Central European Time',
  'eet' = 'Eastern European Time',
  'ist' = 'India Standard Time',
  'cst_china' = 'China Standard Time',
  'jst' = 'Japan Standard Time',
}

export enum TimezoneValues {
  'EST' = 'est',
  'CST' = 'cst',
  'MST' = 'mst',
  'PST' = 'pst',
  'CET' = 'cet',
}

export const LeadSchema = ProjectSchema.pick({
  dtplannedstart: true,
  dtplannedend: true,
  project_id: true,
  name: true,
  project_sf_id: true,
  owner_id: true,
  sf_stage: true,
  sf_description: true,
  sf_description_updated_at: true,
  sf_notes_updated_at: true,
  is_plan_included: true,
  sf_expected_monthly_revenue: true,
  sf_locked_logs: true,
  is_sf_locked: true,
  dt_archived: true,
  isarchived: true,

  client_id: true,
  hire_group: true,
  hard_date: true,
  timezone: true,
  expected_monthly_revenue: true,
  expected_monthly_cost: true,
  expected_monthly_margin: true,
  expected_team_size: true,
  islead: true,
  dtupdate: true,
  dtcreate: true,
  manageTeam: true,
  signedContractURL: true,
  equipmentNeeded: true,
  is_pre_opportunity: true,
  duration_weeks: true,
  is_draft_opportunity: true,
  is_starter_project: true,
  confidence_level: true,
}).extend({
  duration: z.number(),
});
export type Lead = z.infer<typeof LeadSchema>;

export const ERROR_PRJ_OPP_NOT_FOUND = 'Project/Opportunity not found.';
