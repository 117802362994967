import { PaginationQuery } from '@ct-internal/api';

export const pageToSkipPagination = (current: number, pageSize: number): PaginationQuery => ({
  skip: (current - 1) * pageSize,
  limit: pageSize,
});

export const skipToPagePagination = (skip: number | undefined, limit: number | undefined) => {
  if (skip === undefined || limit === undefined) {
    return {};
  }
  const current = skip / limit + 1;
  if (current % 1 > 0) {
    console.warn('Invalid pagination combination, skip must be divisible by limit.');
    return {};
  }
  return { current: skip / limit + 1, pageSize: limit };
};
