import { ReactNode } from 'react';
import { Skeleton, Card, Space, Alert } from 'antd';

import './index.css';

interface SummaryProps {
  loading?: boolean;
  error?: string | unknown;
  empty?: boolean;
  children: ReactNode;
}

const Summary = ({ loading, empty, error, children }: SummaryProps) => {
  if (typeof error === 'string') {
    return (
      <Card className="reports-summary">
        <Alert message="Error" description={error} type="error" />
      </Card>
    );
  }

  if (loading || empty || error) {
    return (
      <Card className="reports-summary">
        <Skeleton />
      </Card>
    );
  }

  return (
    <Card className="reports-summary">
      <Space direction="vertical" size={8} style={{ width: '100%' }}>
        {children}
      </Space>
    </Card>
  );
};

export default Summary;
