import { ReactNode } from 'react';
import { Row, Col } from 'antd';

import './index.css';

interface SummaryHeaderProps {
  label: string;
  children?: ReactNode;
}

const SummaryHeader = ({ children, label }: SummaryHeaderProps) => (
  <Row className="header">
    <Col flex="auto">
      <b>{label}</b>
    </Col>
    <Col flex="none">
      <b>{children}</b>
    </Col>
  </Row>
);

export default SummaryHeader;
