import { z } from 'zod';
import { Lead, LeadSchema } from './base';
import { Endpoint, Method } from '../../infrastructure';

export const LeadSetConfidenceLevelRequestSchema = LeadSchema.pick({
  confidence_level: true,
});
export type LeadSetConfidenceLevelRequest = z.infer<typeof LeadSetConfidenceLevelRequestSchema>;

export const LeadSetConfidenceLevelParamsSchema = z.object({
  project_id: z.string(),
});
export type LeadSetConfidenceLevelParams = z.infer<typeof LeadSetConfidenceLevelParamsSchema>;

export type LeadSetConfidenceLevelResponse = Lead;

export type LeadSetConfidenceLevelEndpoint = Endpoint<
  Method.PUT,
  `/api/v2/sql-leads/${LeadSetConfidenceLevelParams['project_id']}/confidence-level`,
  LeadSetConfidenceLevelResponse,
  LeadSetConfidenceLevelParams,
  undefined,
  LeadSetConfidenceLevelRequest
>;
