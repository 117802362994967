const url_prefix = process.env.URL_PREFIX
  ? process.env.URL_PREFIX
  : process.env.REACT_URL_PREFIX || '';

const envs: { [key: string]: any } = {
  local: {
    ENV: 'local',
    API_BASE: 'https://local.cleverbuild.biz:8043',
  },
  e2e: {
    ENV: 'local',
    API_BASE: 'http://localhost:8043',
  },
  dev: {
    ENV: 'development',
    API_BASE: `https://${url_prefix}brains-api.clevertech.dev`,
  },
  prod: {
    ENV: 'production',
    API_BASE: 'https://brains-api.clevertech.biz',
  },
};

const localHostnames = ['local.cleverbuild.biz', 'localhost', '127.0.0.1', 'hire-react'];

const calculateConfig = () => {
  const envFromEnvirontmentVar = process.env.CONFIG_ENV || process.env.REACT_CONFIG_ENV;
  if (envFromEnvirontmentVar) {
    return envs[envFromEnvirontmentVar];
  }

  if (typeof window === 'undefined') {
    return envs['local'];
  }

  const { hostname } = window.location;
  if (localHostnames.indexOf(hostname) >= 0) {
    return envs['local'];
  }

  const hostnameParts = hostname.split('.');
  const subdomain = hostnameParts[0];
  if (
    (subdomain === 'hire' || subdomain === 'brains') &&
    hostnameParts[hostnameParts.length - 1] === 'biz'
  ) {
    return envs['prod'];
  }

  const env = hostnameParts[hostnameParts.length - 1];
  const config = envs[env];
  return config;
};

const config = calculateConfig();

export default config;
