import { useCallback, useEffect, useState } from 'react';
import { PaginationQuery } from '@ct-internal/api';

type UsePaginationReturn = [PaginationQuery, (pagination: PaginationQuery) => void, () => void];

export const DefaultPagination: PaginationQuery = { skip: 0, limit: 20 };

const usePagination = (
  initialPagination = DefaultPagination,
  dependencies: any[] = [],
): UsePaginationReturn => {
  const [pagination, setPagination] = useState(initialPagination);

  const updatePagination = useCallback(
    (pagination: PaginationQuery) => setPagination(pagination),
    [],
  );

  const resetPagination = useCallback(() => setPagination(initialPagination), []);

  useEffect(() => {
    if (pagination === initialPagination) {
      return;
    }
    resetPagination();
  }, dependencies);

  return [pagination, updatePagination, resetPagination];
};

export default usePagination;
